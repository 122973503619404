<section class="home-section" *ngIf="!partner">
  <div class="print-list-button">
    <button mat-icon-button (click)="openPrintDialog()"><mat-icon>print</mat-icon></button>
  </div>

  <div class="mat-button-toggle-wrapper">
    <mat-button-toggle-group name="topics" value="arrival" #group="matButtonToggleGroup"
      (change)="changeTopic(group.value); searchExpansion.expanded = false">
      <mat-button-toggle value="arrival">Hämtlista</mat-button-toggle>
      <mat-button-toggle value="departure">Bokningar</mat-button-toggle>
    </mat-button-toggle-group>

    <div class="upcoming-date-range" *ngIf="timeRange">
      <p>Visar bokningar</p>
      <p>
        {{ formatDate(timeRange.fromHour) }}
        {{ formatTime(timeRange.fromHour) }} <mat-icon>arrow_forward</mat-icon>
        {{ formatDate(timeRange.toHour) }} {{ formatTime(timeRange.toHour) }}
      </p>
    </div>
  </div>

  <mat-expansion-panel class="mat-elevation-z0" #searchExpansion hideToggle disabled>
    <mat-expansion-panel-header>
      <button mat-flat-button class="toogle-search-expansion" *ngIf="!searchExpansion.expanded"
        (click)="searchExpansion.expanded = true" color="primary">
        Sök i {{ currentTopic === "arrival" ? "Hämtlista" : "Bokningar" }}
      </button>
      
      <button mat-flat-button class="toogle-search-expansion" *ngIf="searchExpansion.expanded"
        (click)="searchExpansion.expanded = false" color="primary">
        Stäng sök
      </button>
    </mat-expansion-panel-header>

    <div class="search-result">
      <app-search [currentDirection]="currentTopic" [bookingsGroup]="
        currentTopic === 'arrival' ? 
        groupedArrivalBookings : 
        groupedDepartureBookings
      ">
      </app-search>
    </div>
  </mat-expansion-panel>

  <app-current-bookings [groupedBookings]="
      currentTopic === 'arrival'
        ? groupedArrivalBookings
        : groupedDepartureBookings
    " [errorCode]="dispatchBookingsErrorCode">
  </app-current-bookings>
</section>