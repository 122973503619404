import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BookingDTO, OrderDTO, OrderItemDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { OrderService } from 'src/app/core/services/order.service';
import { GetBooking } from 'src/app/core/states/bookings/booking.actions';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss']
})
export class OrderItemsComponent {
  @Input() order?: OrderDTO
  @Input() currentBooking?: BookingDTO
  @Input() renderType: 'order' | 'booking' = 'booking'
  @Output() onGetBooking: EventEmitter<number> = new EventEmitter()
  private onDestroy$ = new Subject<void>();

  constructor(private store: Store, private router: Router){}

  showBooking(id: number | undefined) {
    if (id !== undefined) {
      this.onGetBooking.emit(id);
      this.router.navigate(['bokning', id]);
    }
  }
  
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.order = undefined;
  }
}
