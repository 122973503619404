import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserDTO } from 'parking-sdk';
import { DateService } from 'src/app/core/services/date.service';
import { UsersState } from 'src/app/core/states/users/users.state';

@Component({
  selector: 'app-register-opened-receipt',
  templateUrl: './register-opened-receipt.component.html',
  styleUrls: ['./register-opened-receipt.component.scss'],
})
export class RegisterOpenedReceiptComponent {
  @Input() comment?: string;
  date?: string;
  time?: string;
  user?: UserDTO;

  constructor(private store: Store, private dateService: DateService) {}

  ngOnInit() {
    this.store
      .selectOnce(UsersState.loggedInUser)
      .subscribe((user) => (this.user = user));

    this.date = this.formatDate(new Date());
    this.time = this.formatTime(new Date());
  }

  formatDate(date: Date): string {
    return this.dateService.formatDate(date);
  }
  formatTime(date: Date): string {
    return this.dateService.formatTime(date);
  }
}
