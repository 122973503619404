<div class="container" mat-dialog-content>
  <h3>Skapa ny användare</h3>
  <form [formGroup]="userForm">
    <mat-form-field>
      <mat-label>Användarnamn</mat-label>
      <input matInput formControlName="username" autocomplete="off" required spellcheck="false">
      <mat-error>Användarnamn måste vara en e-postadress</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Roller</mat-label>
      <mat-select formControlName="roles" autocomplete="off" required multiple>
        <ng-container *ngIf="roleOptions$ | async as roleOptions">
          <mat-option *ngFor="let role of roleOptions" [value]="role">{{ role.label }}</mat-option>
        </ng-container>
      </mat-select>
      <mat-error>Minst en roll måste väljas</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="partners">
      <mat-label>Samarbetspartners</mat-label>
      <mat-select #partnerSelect formControlName="partner" autocomplete="off" required>
        <ng-container>
          <mat-option *ngFor="let partner of partners" [value]="partner">{{ partner.name }} </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Lösenord</mat-label>
      <input matInput type="password" formControlName="newPassword" autocomplete="new-password" spellcheck="false">
      <mat-hint><i>Minst 6 tecken</i></mat-hint>
      <mat-error>Lösenord måste vara minst 6 tecken</mat-error>
    </mat-form-field>

    <p class="info">Om lösenordet lämnas tomt kommer ett mejl att skickas till den angivna e-postadressen med en länk där användaren
      själv får välja lösenord.</p>

    <div mat-dialog-actions class="buttons">
      <button mat-button mat-dialog-close>Avbryt</button>
      <button mat-raised-button color="primary" (click)="onCreateClick()" [disabled]="!userForm.valid">Skapa</button>
    </div>
  </form>
</div>