import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DefaultService, RoleDTO, UserDTO } from 'parking-sdk';
import * as Actions from './user.actions';
import { map, of, switchMap, tap } from 'rxjs';

export interface UsersStateModel {
  users: UserDTO[];
  loggedInUser?: UserDTO
  roles: RoleDTO[];
}

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    users: [],
    loggedInUser: undefined,
    roles: [],
  },
})
@Injectable()
export class UsersState {
  constructor(private defaultService: DefaultService) {}

  @Selector()
  static loggedInUser(state: UsersStateModel) {
    return state.loggedInUser
  }

  @Selector()
  static currentUsers(state: UsersStateModel) {
    return state.users
  }

  @Selector()
  static currentRoles(state: UsersStateModel) {
    return state.roles
  }

  @Action(Actions.GetUsers)
  getUsers(context: StateContext<UsersStateModel>) {
    return this.defaultService.getUsers().pipe(
      tap((users) => {
        context.patchState({ users: users.content });
      })
    );
  }
  
  @Action(Actions.SetLoggedInUser)
  setLoggedInUser(context: StateContext<UsersStateModel>) {
    return this.defaultService.getMe().pipe(
      tap((user) => {
        context.patchState({loggedInUser: user})
      })
    )
  }

  @Action(Actions.GetRoles)
  getRoles(context: StateContext<UsersStateModel>) {
    return this.defaultService.getRoles().pipe(
      tap((roles) => {
        context.patchState({ roles: roles });
      })
    );
  }

  //TODO: Better error handling.
  @Action(Actions.AddUser)
  addUser(context: StateContext<UsersStateModel>, action: Actions.AddUser) {
    const { user } = action;

    return this.defaultService.createUser(user).pipe(
      tap((newUser) => {
        context.patchState({
          users: [...context.getState().users, newUser],
        });
      })
    );
  }

  @Action(Actions.RemoveUser)
  removeUser(
    context: StateContext<UsersStateModel>,
    action: Actions.RemoveUser
  ) {
    const { userId } = action;

    return this.defaultService.deleteUser(userId).pipe(
      tap(() => {
        context.patchState({
          users: context.getState().users.filter((user) => user.id !== userId),
        });
      })
    );
  }

  @Action(Actions.UpdateUser)
  updateUser(
    context: StateContext<UsersStateModel>,
    action: Actions.UpdateUser
  ) {
    const { userId, updatedUser } = action;

    return this.defaultService.updateUser(userId, updatedUser).pipe(
      tap(() => {
        const currentUsers = context.getState().users;
        const updatedUsers = currentUsers.map((user) =>
          user.id === userId ? { ...user, ...updatedUser } : user
        );

        context.patchState({
          users: updatedUsers,
        });
      })
    );
  }

  @Action(Actions.ResetUsersState)
  resetState(context: StateContext<UsersStateModel>) {
    context.setState({
      users: [],
      loggedInUser: undefined,
      roles: [],
    });
  }
}
