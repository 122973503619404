import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './components/login/login.component'
import { BookingComponent } from './pages/booking/booking.component'
import { AuthguardService as AuthGuard } from "./core/services/auth-guard.service"
import { ConfirmGuardService as AuthConfirm } from "./core/services/confirm-guard.service";
import { CheckoutGuardService as AuthCheckout } from './core/services/checkout-guard.service';
import { HomeComponent } from "./pages/home/home.component"
import { BookingCreateComponent } from './pages/booking-create/booking-create.component';
import { RelyitComponent } from './pages/relyit/relyit.component'
import { ConfirmComponent } from './pages/relyit/confirm/confirm.component'
import { UserAdministrationComponent } from './pages/user-administration/user-administration.component'
import { ParkingLotAdministrationComponent } from './pages/parking-lot-administration/parking-lot-administration.component'
import { PrepaidComponent } from './pages/prepaid/prepaid.component'
import { PricelistsComponent } from './pages/pricelists/pricelists.component'
import { PricelistComponent } from './pages/pricelist/pricelist.component'
import { ScheduleComponent } from './pages/schedule/schedule.component'
import { CheckoutComponent } from './pages/checkout/checkout.component'
import { RequestPasswordResetComponent } from './pages/request-password-reset/request-password-reset.component'
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component'
import { OrdersComponent } from './pages/orders/orders.component'
import { GarageComponent } from './pages/garage/garage.component'
import { BookingsComponent } from './pages/bookings/bookings.component'
import { SmsSettingsComponent } from './pages/sms-settings/sms-settings.component'
import { AnonymizeComponent } from './pages/anonymize/anonymize.component'
import { RefundComponent } from './pages/refund/refund.component'
import { PaymentReportComponent } from './pages/reports/payment-report/payment-report.component'
import { QrComponent } from './pages/qr/qr.component'
import { OccupancyReportComponent } from './pages/reports/occupancy-report/occupancy-report.component'
import { AdvancePaymentsReportComponent } from './pages/reports/advance-payments-report/advance-payments-report.component'
import { DailyArticleReportComponent } from './pages/reports/daily-article-report/daily-article-report.component'
import { DailyPaymentMethodReportComponent } from './pages/reports/daily-payment-method-report/daily-payment-method-report.component'
import { OrderComponent } from './pages/order/order.component'
import { PartnershipComponent } from './pages/partnership/partnership.component'

const routes: Routes = [
  { path: 'order/:id', component: OrderComponent, canActivate: [AuthGuard] },
  { path: 'avidentifiering', component: AnonymizeComponent, canActivate: [AuthGuard] },
  { path: 'ordrar', component: OrdersComponent, canActivate: [AuthGuard] },
  { path: 'ny-bokning', component: BookingCreateComponent, canActivate: [AuthGuard], canDeactivate: [AuthConfirm] },
  { path: 'redigera-bokning/:id', component: BookingCreateComponent, canActivate: [AuthGuard], canDeactivate: [AuthConfirm] },
  { path: 'logga-in', component: LoginComponent },
  { path: 'glomt-losenord', component: RequestPasswordResetComponent },
  { path: 'aterstall-losenord/:token', component: ResetPasswordComponent },
  { path: 'qr/:guid', component: QrComponent, data: { hideLayout: true } },
  { path: 'hem', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'relyit', component: RelyitComponent, canActivate: [AuthGuard] },
  { path: 'anvandare', component: UserAdministrationComponent, canActivate: [AuthGuard] },
  { path: 'prislistor', component: PricelistsComponent, canActivate: [AuthGuard] },
  { path: 'prislista/:id', component: PricelistComponent, canActivate: [AuthGuard] },
  { path: 'klarna-confirm', component: ConfirmComponent }, // No authentication
  { path: 'bokning/:id', component: BookingComponent, canActivate: [AuthGuard] },
  { path: 'antal-p-platser', component: ParkingLotAdministrationComponent, canActivate: [AuthGuard]},
  { path: 'abonnemang', component: PrepaidComponent, canActivate: [AuthGuard] },
  { path: 'schema', component: ScheduleComponent, canActivate: [AuthGuard] },
  { path: 'hantera-betalning', component: CheckoutComponent, canActivate: [AuthGuard], canDeactivate: [AuthCheckout] },
  { path: 'aterbetalning', component: RefundComponent, canActivate: [AuthGuard], canDeactivate: [AuthCheckout] },
  { path: 'garage', component: GarageComponent, canActivate: [AuthGuard] },
  { path: 'alla-bokningar', component: BookingsComponent, canActivate: [AuthGuard] },
  { path: 'sms-installningar', component: SmsSettingsComponent, canActivate: [AuthGuard] },
  { path: 'dagsrapport-betalmetod', component: DailyPaymentMethodReportComponent, canActivate: [AuthGuard] },
  { path: 'dagsrapport-artiklar', component: DailyArticleReportComponent, canActivate: [AuthGuard] },
  { path: 'forskottsbetalningar', component: AdvancePaymentsReportComponent, canActivate: [AuthGuard] },
  { path: 'betalningsrapport', component: PaymentReportComponent, canActivate: [AuthGuard] },
  { path: 'belaggningsrapport', component: OccupancyReportComponent, canActivate: [AuthGuard] },
  { path: 'partnerportalen', component: PartnershipComponent, canActivate: [AuthGuard] },
  { path: '**', component: HomeComponent, canActivate: [AuthGuard] },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
