import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AvailabilityDTO, DefaultService } from "parking-sdk";
import { AddAvailability, DeleteAvailability, GetAvailabilities, UpdateAvailability } from "./availabilities.actions";
import { tap } from "rxjs";

export interface AvailabilitiesStateModel {
    availabilities: AvailabilityDTO[];
  }
  
  @State<AvailabilitiesStateModel>({
    name: 'availabilities',
    defaults: {
      availabilities: []
    },
  })
  @Injectable()
  export class AvailabilitiesState {
    constructor(private defaultService: DefaultService) {}
  
    @Selector()
    static currentAvailabilities(state: AvailabilitiesStateModel) {
      return state.availabilities
    }
    
    @Action(GetAvailabilities)
    getAvailabilities(context: StateContext<AvailabilitiesStateModel>) {
      return this.defaultService.getAvailabilitiesAdmin().pipe(
        tap((availabilities) => {
          context.patchState({ availabilities });
        })
      );
    }

    @Action(UpdateAvailability)
    updateAvailability(context: StateContext<AvailabilitiesStateModel>, action: UpdateAvailability) {
        const { availabilitytId, availability } = action;

        return this.defaultService.updateAvailabilityAdmin(availabilitytId, availability).pipe(
            tap(() => {
                const currentAvailabilities = context.getState().availabilities;
                const updatedAvailabilities = currentAvailabilities.map((curAvailability) => 
                    curAvailability.availabilityId === availabilitytId ? availability : curAvailability
                );
                context.patchState({
                    availabilities: updatedAvailabilities,
                });
            })
        );
    }

    @Action(AddAvailability)
    addAvailability(context: StateContext<AvailabilitiesStateModel>, action: AddAvailability) {
        const { availability } = action;
        return this.defaultService.createAvailabilityAdmin(availability).pipe(
            tap((newAvailability) => {
                const currentAvailabilities = context.getState().availabilities;
                context.patchState({
                    availabilities: [...currentAvailabilities, newAvailability]
                });
            })
        );
    }


    @Action(DeleteAvailability)
    deleteAvailability(context: StateContext<AvailabilitiesStateModel>, action: DeleteAvailability) {
        const { availabilitytId } = action;

        return this.defaultService.deleteAvailabilityAdmin(availabilitytId).pipe(
            tap(() => {
                const filteredAvailabilities = context.getState().availabilities.filter(availability => availability.availabilityId !== availabilitytId)
                context.patchState({
                    availabilities: [...filteredAvailabilities]
                });
            })
        )
    }

}