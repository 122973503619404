import { NgModule } from '@angular/core';
import { RegisterComponent } from './register.component';
import { RegisterOpenedReceiptComponent } from './register-opened-receipt/register-opened-receipt.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    RegisterComponent,
    RegisterOpenedReceiptComponent,
  ],
  imports: [SharedModule],
  exports: [RegisterComponent],
})
export class RegisterModule {}
