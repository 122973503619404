<article class="side-search-container">
    <h2>Sök bland alla bokningar</h2>
    <!-- Searchbox -->
    <section class="side-searchbox-wrapper {{termTooShort ? 'term-too-short': null}}">
        <input type="search" placeholder="Reg.nr/bokningsnr." [formControl]="searchInput" #searchBox
            data-test="side-menu-search" (input)="search(searchInput.value || undefined)" spellcheck="false"
            autocomplete="off">
    </section>

    <div class="side-search-result-wrapper">
        <!-- Search result upcoming -->
        <div *ngIf="searchResultUpcoming && !termNotFound">
            <p>Kommande bokningar</p>
            <app-bookings-table [bookings]="searchResultUpcoming" (closeDrawer)="close()"></app-bookings-table>
        </div>

        <!-- Search result old -->
        <div *ngIf="searchResultHistory && !termNotFound">
            <p>Historiska bokningar</p>
            <app-bookings-table [bookings]="searchResultHistory" (closeDrawer)="close()"></app-bookings-table>
        </div>

        <!-- Booking not found -->
        <div class="empty-content" *ngIf="!searchResultHistory && !searchResultUpcoming">
            <p *ngIf="termNotFound">Kunde inte hitta en bokning med "{{termNotFound}}"</p>
            <p *ngIf="!termNotFound">Sök på registrerings- eller bokningsnummer</p>
        </div>

        <div class="spinner-wrapper" *ngIf="showSpinner">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</article>