<ul class="order-items" *ngIf="order">
  <li *ngFor="let orderItem of order?.orderItems" [ngClass]="{ 'feature': orderItem?.orderItemType === 'FEATURE' }">
    <div class="order-item">
      <span *ngIf="
            orderItem.orderItemType === 'RESOURCE' &&
            orderItem.booking?.registrationNumber ===
              currentBooking?.registrationNumber
          "><mat-icon>directions_car</mat-icon>{{ orderItem.booking?.registrationNumber }}
          <strong>{{orderItem.booking?.bookingStatus === 'CANCELLED' ? 'AVBOKAD': '' }}</strong>
      </span>

      <a *ngIf="
            orderItem.orderItemType === 'RESOURCE' &&
            orderItem.booking?.registrationNumber !==
              currentBooking?.registrationNumber"
        (click)="showBooking(orderItem.booking?.bookingId)"
        [ngClass]="{'order': renderType === 'order'}"
        title="Gå till bokningssidan">
        <mat-icon>directions_car</mat-icon>
        <span>{{orderItem.booking?.registrationNumber }}</span>
        <strong>{{orderItem.booking?.bookingStatus === 'CANCELLED' ? 'AVBOKAD': '' }}</strong>
        <mat-icon class="arrow">chevron_right</mat-icon>
      </a>

      <span *ngIf="orderItem.orderItemType === 'FEATURE'">
        <span class="connect-to-car"></span>
        {{ orderItem.feature?.name }} {{ orderItem.feature?.registrationNumber || '' }}
      </span>
      
      <span *ngIf="orderItem.orderItemType === 'PREPAIDTICKET'">
        {{ orderItem.prepaidTicket?.name || 'okänt namn' }} (abonnemang)
      </span>

      <span [ngClass]="{ 'value-to-end': orderItem.amount !== undefined }">
        {{ orderItem.amount | number : "1.0-0" }} kr
      </span>
    </div>

    <div *ngIf="orderItem.orderItemType === 'PREPAIDTICKET'" class="discount-item order">
      <span>Kommentar: {{orderItem.prepaidTicket?.comment || 'Ingen kommentar angiven'}}</span>
    </div>

    <div *ngIf="orderItem.discount && orderItem.discount > 0" class="discount-item" [ngClass]="{'order': renderType === 'order'}">
      <span>Rabatt: {{orderItem.discountComment}}</span>
      <span class="value-to-end">-{{orderItem.discount}} kr</span>
    </div>
  </li>
</ul>