import { NgModule } from '@angular/core';

import { OrdersComponent } from './orders.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [OrdersComponent],
  imports: [SharedModule, MatPaginatorModule],
  exports: [OrdersComponent],
})
export class OrdersModule {}
