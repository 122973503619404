import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ResetOrderState } from '../states/orders/orders.action';
import { Observable, of, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuardService {

  constructor(private dialog: MatDialog, private store: Store) { }

  canDeactivate(component: any): Observable<boolean> {
    if(component.noReset){
      return of(true)
    } else if(!component.newOrder && !component.paymentFinished) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Avbryt betalning',
          message: ['Är du säker på att du vill avbryta betalningen?'],
          yesBtnText: 'Ja',
          noBtnText: 'Nej'
        }
      });

      return confirmDialog.afterClosed().pipe(tap((res) => {
        if(res) {
          this.store.dispatch(new ResetOrderState());
          return true;
        }
        else {
          return false;
        }
      }));
    }
    else if(component.paymentFinished) {
      this.store.dispatch(new ResetOrderState());
      return of(true);
    }
    else {
      return of(true);
    }
  }
}
