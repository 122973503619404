<!-- Defaults to confirm a deletion, message param is required -->
<div class="confirm-wrapper">
    <div mat-dialog-content >
        <h4>{{data.title || 'Är du säker på att du vill ta bort:'}}</h4>
        <p *ngFor="let msg of data.message">{{msg}}</p>
    </div>
    <div mat-dialog-actions id="buttons">
        <button mat-button (click)="onCancelClick()">{{data.noBtnText || 'Avbryt'}}</button>
        <button mat-raised-button (click)="onConfirmClick()" color="{{!data.yesBtnText ? 'warn' : 'primary'}}" data-test="confirm-dialog-yes-btn">{{data.yesBtnText || 'Ta bort'}}</button>
    </div>
</div>