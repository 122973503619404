import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartnerDTO, RoleDTO, UserDTO } from 'parking-sdk';
import { Subject, Observable, takeUntil } from 'rxjs';
import { DefaultService } from 'parking-sdk';
import {
  GetRoles,
  RemoveUser,
  UpdateUser,
} from 'src/app/core/states/users/user.actions';
import { UsersState } from 'src/app/core/states/users/users.state';
import { Store } from '@ngxs/store';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  private onDestroy$ = new Subject<void>();
  editUser: UserDTO;
  editUserForm?: FormGroup;
  partners?: PartnerDTO[];
  selectedPartner?: PartnerDTO;
  roleOptions$: Observable<RoleDTO[]>;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: UserDTO },
    private store: Store,
    public defaultService: DefaultService,
    private fb: FormBuilder
  ) {
    // Copy the user to avoid modifying the original in the parent component
    this.editUser = { ...data.user };
    this.roleOptions$ = this.store.select(UsersState.currentRoles);
  }

  ngOnInit() {
    this.editUserForm = this.fb.group({
      username: [
        this.editUser.username,
        [Validators.required, Validators.email],
      ],
      roles: [this.editUser.roles, Validators.required],
      newPassword: [this.editUser.newPassword, Validators.minLength(6)],
      partner: [this.getPartner()],
    });

    this.isPartner() && this.getPartners();
    this.store.dispatch(GetRoles);
    this.selectedPartner = this.getPartner();

    /* Listen to changes on roles */
    this.editUserForm.controls['roles'].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((changes: any[]) => {
        if (changes?.some((change) => change.name === 'ROLE_PARTNER')) {
          this.getPartners();
        } else {
          this.partners = undefined;
          this.editUserForm?.controls['partner'].setValue(undefined);
        }
      });
  }

  isPartner(): boolean {
    return (
      this.editUser.roles?.some((role) => role.name === 'ROLE_PARTNER') || false
    );
  }

  compareSelected(a: RoleDTO, b: RoleDTO) {
    return a?.id === b?.id;
  }

  compareSelectedPartner(a: PartnerDTO, b: PartnerDTO) {
    return a?.partnerId === b?.partnerId;
  }

  // Force reset password for user next time they log in
  resetPassword() {
    this.defaultService
      .requestPasswordResetAdmin({ email: this.editUser.username })
      .subscribe({
        next: () => {},
        error: () => {
          console.log('Fel');
        },
      });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSaveClick() {
    if (this.editUser.id && this.editUserForm?.valid) {
      /* If empty password, set undefined. Backend will send email to user with password link if password is undefined. */
      let newPassword = this.editUserForm.get('newPassword')?.value;
      if (newPassword && newPassword.trim() === '') {
        newPassword = undefined;
      }

      /* If role is partner, connect partner obj to role */
      let roles: RoleDTO[] = this.editUserForm.get('roles')?.value;
      if (this.editUserForm.controls['partner'].value) {
        roles = roles.map((role) => {
          if (role.name === 'ROLE_PARTNER') {
            return {
              ...role,
              partner: this.editUserForm?.controls['partner'].value,
            };
          } else return role;
        });
      }

      const updatedUser: UserDTO = {
        id: this.editUser.id,
        username: this.editUserForm.value.username,
        roles: roles,
        newPassword: newPassword,
        // Add other properties as needed
      };

      this.store.dispatch(new UpdateUser(this.editUser.id, updatedUser));
      this.dialogRef.close();
    } else {
      console.error('User ID is missing. Unable to update user.');
    }
  }

  onDeleteClick() {
    let userIdToDelete = this.editUser.id;
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Är du säker på att du vill ta bort användare "${this.editUser.username}"?`,
        message: [`Det kommer inte gå att återställa kontot.`],
      },
    });
    confirmDialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result && userIdToDelete) {
          this.store.dispatch(new RemoveUser(userIdToDelete));
          this.dialogRef.close();
        }
      });
  }

  getPartner(): PartnerDTO | undefined {
    return (
      this.editUser.roles?.find((role) => role.partner)?.partner || undefined
    );
  }

  getPartners(): void {
    this.defaultService
      .findPartners()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((partners) => {
        this.partners = partners;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
