import { PriceListDTO } from 'parking-sdk'

export class GetPricelists {
  static readonly type = '[Pricelist] Get Pricelist'
}

export class GetPricelistById {
  static readonly type = '[Pricelist] Get Pricelist By Id'
  constructor(public priceListId: number) {}
}

export class CreatePricelist {
  static readonly type = '[Pricelist] Create Pricelist'
  constructor(public priceList: PriceListDTO) {}
}

export class DeletePricelist {
  static readonly type = '[Pricelist] Delete Pricelist'
  constructor(public priceListId: number) {}
}

export class UpdatePriceList {
    static readonly type = '[Pricelist] Update Pricelist'
    constructor(public priceList: PriceListDTO) {}
}

export class GetFeatures {
  static readonly type = '[Pricelist] Get Features based on mainFeatureType to pricelist'
  constructor(public mainFeatureType: string) {}
}

export class GetAllFeatures {
  static readonly type = '[Pricelist] Get all features';
  constructor() {}
}

export class GetResources {
  static readonly type = '[Pricelist] Get Resource to Pricelist'
}