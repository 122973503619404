<div class="daily-article-report-wrapper">
    <h2>Dagsrapport - artiklar</h2>

    <!-- Datepicker -->
    <div class="date-and-export">
        <mat-form-field>
            <mat-label>Välj period</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Startdatum" [formControl]="startDate">
                <input matEndDate placeholder="Slutdatum" [formControl]="endDate">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <button (click)="downloadReport()" class="button slim secondary" title="Exportera till CSV">Exportera<mat-icon>save_alt</mat-icon></button>
    </div>

    <!-- Table -->
    <div>
        <table mat-table [dataSource]="articleReport" *ngIf="articleReport">
            <ng-container matColumnDef="article">
                <th mat-header-cell *matHeaderCellDef>Artikel</th>
                <td mat-cell *matCellDef="let report">{{report.article}}</td>
                <td mat-footer-cell *matFooterCellDef>Total </td>
            </ng-container>

            <ng-container matColumnDef="payments">
                <th mat-header-cell *matHeaderCellDef class="number-data">Betalningar</th>
                <td mat-cell *matCellDef="let report" class="number-data">{{report.payments | number: "1.0-0"}} kr</td>
                <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('payments') | number: "1.0-0"}} kr</td>
            </ng-container>

            <ng-container matColumnDef="refunds">
                <th mat-header-cell *matHeaderCellDef class="number-data">Återköp</th>
                <td mat-cell *matCellDef="let report" class="number-data">{{report.refunds | number: "1.0-0"}} kr</td>
                <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('refunds') | number: "1.0-0"}} kr</td>
            </ng-container>

            <ng-container matColumnDef="brutto">
                <th mat-header-cell *matHeaderCellDef class="number-data">Total (inkl. moms)</th>
                <td mat-cell *matCellDef="let report" class="number-data">{{report.brutto | number: "1.0-0"}} kr</td>
                <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('brutto') | number: "1.0-0"}} kr</td>
            </ng-container>

            <ng-container matColumnDef="netto">
                <th mat-header-cell *matHeaderCellDef class="number-data">Netto</th>
                <td mat-cell *matCellDef="let report" class="number-data">{{report.netto | number: "1.0-0"}} kr</td>
                <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('netto') | number: "1.0-0"}} kr</td>
            </ng-container>

            <ng-container matColumnDef="vat">
                <th mat-header-cell *matHeaderCellDef class="number-data">Moms</th>
                <td mat-cell *matCellDef="let report" class="number-data">{{report.vat | number: "1.0-0"}} kr</td>
                <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('vat') | number: "1.0-0"}} kr</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['article', 'payments', 'refunds', 'brutto', 'netto', 'vat']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['article', 'payments', 'refunds', 'brutto', 'netto', 'vat']"></tr>
            <tr mat-footer-row *matFooterRowDef="['article', 'payments', 'refunds', 'brutto', 'netto', 'vat']"></tr>
        </table>
    </div>
    
    <!-- Empty set info -->
    <div *ngIf="(articleReport?.length || 0) <= 0" class="empty-content">
        <p>Det finns inga data för den valda perioden.</p>
    </div>
</div>