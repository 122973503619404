<h2>Användare</h2>

<div class="container">
    <input type="search" matInput (keyup)="applyFilter($event)" placeholder="Sök Id/Användare/Roll" #input autocomplete="off" spellcheck="false">
  <button class="newUser button slim with-icon" (click)="createUser()"><mat-icon>add</mat-icon> Ny Användare</button>

  <table *ngIf="users" mat-table [dataSource]="users">

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let user"> {{ user.id }} </td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Namn </th>
      <td mat-cell *matCellDef="let user"> {{ user.username }} </td>
    </ng-container>

    <!-- Roles Column -->
    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef> Roller </th>
      <td mat-cell *matCellDef="let user">
        <ng-container *ngFor="let role of user.roles; let last = last">
          <span>{{ role.label }}</span>
          <span *ngIf="!last">, </span>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['id', 'username', 'roles']"></tr>
  	<tr class="mat-row" mat-row *matRowDef="let user; columns: ['id', 'username', 'roles']; let i = index" (click)="openEditDialog(user)">

    <!-- Row shown when there is no matching data. -->
    <tr *matNoDataRow>
      <td class="mat-cell" colspan="3" style="padding: 20px">Ingen användare matchar sökning. "{{input.value}}"</td>
    </tr>
  </table>
</div>