import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BookingDTO, DefaultService, FeatureDTO, FlightBookingDTO, OrderDTO, OrderItemDTO, PageBookingDTO, PageUserDTO, PaymentDTO, PaymentRequestDTO, PrepaidTicketDTO, PriceListDTO, ResourceStatusDTO, RoleDTO, SettingDTO, TransactionStatusResponseDTO } from 'parking-sdk';
//import { BookingDTO } from 'parking-sdk/model/bookingDTO';
import { Subject, first, forkJoin, map, of, switchMap, takeUntil, timer } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service'
//import { DefaultService } from 'src/parking-sdk/api/default.service';
//import { OrderDTO } from 'src/parking-sdk/model/orderDTO';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { ExportService } from 'src/app/core/services/export.service';
dayjs.extend(utc);

@Component({
  selector: 'app-relyit',
  templateUrl: './relyit.component.html',
  styleUrls: ['./relyit.component.scss']
})
export class RelyitComponent implements OnInit {
  private onDestroy$ = new Subject();
  order: OrderDTO | undefined;
  title = 'Rely IT test';
  klarnaSnippet!: string;
  settings: SettingDTO[] | undefined;
  roles: RoleDTO[] | undefined;
  users: PageUserDTO | undefined;
  bookings: PageBookingDTO | undefined;
  priceLists: PriceListDTO[] | undefined;
  priceList: PriceListDTO | undefined;
  currentFlights: FlightBookingDTO[] | undefined;
  prepaidTickets: PrepaidTicketDTO[] | undefined;
  prepaidTicket: PrepaidTicketDTO | undefined;
  smsPreview: string | undefined = '';

  generateBookingForm = new FormGroup({
    fromDate: new FormControl('', {
      validators: [
        Validators.required
      ],
      updateOn: 'change'
    }),
    toDate: new FormControl('', {
      validators: [
        Validators.required
      ],
      updateOn: 'change'
    })
  });
  resourceStatus: ResourceStatusDTO[] | undefined;
  transactionStatus: TransactionStatusResponseDTO | undefined;
;
  
  constructor(
    private orderService: DefaultService,
    private exportService: ExportService,
    private router: Router,
    private authenticateService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.createOrder()
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined)
    this.onDestroy$.complete()
  }

  createOrder() {
    this.orderService.createOrder('davidtest1', 638712480560000000, 'ad676ad5db3a6d661e8b5365b168194ba35e2561b38f415603c932bdbc24be25')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.order = order;

        // Store order token for private calls
        if (order.token) {
          this.authenticateService.storeOrderToken(order.token);
        }

      });
  }

  /* Remove later */
  generateRandomUppercaseLetters(): string {
    const possibleLetters = 'abcdefghijklmnopqrstuvwxyz';
    let randomLetters = '';

    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * possibleLetters.length);
      randomLetters += possibleLetters.charAt(randomIndex);
    }

    return randomLetters.toUpperCase();
  }



  addBooking() {
    const today = dayjs().utc();
    const passengers = Math.floor((Math.random() * 10) + 1)
    const randomUppercaseLetters = this.generateRandomUppercaseLetters()
    const registrationNumber = randomUppercaseLetters + Math.floor(Math.random() * (999 - 100 + 1) + 100)
    const departureDate = today;
    //const departureDate = today.add(-7, 'day').add(Math.floor(Math.random() * 3), 'day');

    forkJoin([
      this.orderService.getFlightsAdmin(departureDate.format('YYYY-MM-DD'), 'DEPARTURE'),
      this.orderService.getFlightsAdmin(departureDate.add(Math.ceil(Math.random() * 10), 'day').format('YYYY-MM-DD'), 'ARRIVAL')
    ])
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(([departures, arrivals]) => {
      const departure = departures[Math.floor(Math.random()*departures.length)];
      const arrival = arrivals[Math.floor(Math.random()*arrivals.length)];

      let booking: BookingDTO = {
        name: "David Starck",
        phone: "+46739224686",
        email: "david.starck@relyit.se",
        departureDate: departure.suggestedParkingFrom,
       /*  departureDate: new Date("2023-09-10T07:00:00"), */
       /*  arrivalDate: departureDate, */
        arrivalDate: arrival.suggestedParkingTo,
        departureFlight: departure,
        departureFlightNumber: departure.flightNumber,
        arrivalFlight: arrival,
        arrivalFlightNumber: arrival.flightNumber,
        travelHomeFrom: arrival.departureAirport,
        registrationNumber: registrationNumber,
        // registrationNumber: 'KBZ447', // Have prepaidTicket
        resource: {
          resourceId: 3,
          name: "PARKING_OUTDOOR",
          resourceType: "LIMITED_QTY"
        },
        qtyPersons: passengers,
        handLuggageOnly: false,
        bookingStatus: "STARTED",
        // comment: 'David testar att kommentaren kommer fram till Anneli.',
        vehicleType: { vehicleTypeId: 'MEDIUM'},
        engineType: { engineTypeId: 'FUEL'}
      };

      this.orderService.addBookingPrivate(this.order?.orderId || -1, booking)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        console.log('Order created', order);
        this.order = order

        /* Testing updateOrderBooking */
        if (order?.orderItems && order?.orderItems[0].booking) {
          this.orderService.updateOrderBookingPrivate(this.order?.orderId || -1, order?.orderItems[0].booking.bookingId || -1, order?.orderItems[0].booking)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(order => {
              this.order = order
              console.log('Order updated by private call', order);

              if (order && order.orderItems && order.orderItems[0]) {
                //order.orderItems[0].discount = 100;
                this.orderService.updateOrderItemAdmin(order.orderId || -1, order?.orderItems[0].orderItemId|| -1, order?.orderItems[0])
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(order => {
                  this.order = order
                  console.log('Order updated by admin call', order);
                });
              }  
          });

          // preview SMS
          // this.orderService.getSmsPreviewAdmin(order?.orderItems[0].booking)
          // .pipe(takeUntil(this.onDestroy$))
          // .subscribe(smsDTO => {
          //   this.smsPreview = smsDTO.body;
          // });
        }
      })
  
    });
  }

  addFeature() {
    let features: FeatureDTO[] = [
      {
        name: "Handtvätt",
        featureId: 1
      },
      {
        name: "Avspolning",
        featureId: 2
      },
    ];
    

    this.orderService.requestFeaturesPrivate(this.order?.orderId || -1, { features: features} )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.order = order
      })
  }

  addPrepaidTicket() {
    let prepaidTicket: PrepaidTicketDTO = {
      name: "Testgenererat årskort",
      maxPark: 1,
      validFrom: dayjs().toDate(),
      validTo: dayjs().add(1, 'year').toDate(),
      cars: [
        {
          registrationNumber: 'ABC123'
        }
      ],
      comment: 'Genererat av Rely IT testsida',
      amount: 3250
    };
    let orderItem: OrderItemDTO = {
      orderItemType: 'PREPAIDTICKET',
      prepaidTicket: prepaidTicket
    }

    this.orderService.addOrderItemAdmin(this.order?.orderId || -1, orderItem)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.order = order
      })
  }


  checkoutOrderKlarna() {
    if (this.order?.orderId) {
      this.orderService.checkoutOrderPrivate(this.order?.orderId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(orderResponse => {
          if (orderResponse.htmlSnippet) {
            this.klarnaSnippet = orderResponse.htmlSnippet;
          }
        });
    }
  }

  checkoutOrderPax() {
    let abortTimerSubject: Subject<void> = new Subject();

    if (this.order?.orderId) {
      let orderId: number = this.order?.orderId;
      let payment: PaymentDTO = {
        paymentMethod: {paymentMethodId: 'PAX'},
        transactionType: 'PAYMENT',
        terminalId: 1,
        amount: this.order.amountToPay
      };
      this.orderService.checkoutOrderAdmin(orderId, payment)
        .pipe(
          first(),
          switchMap((transactionResponse) => {
            if (transactionResponse.uti) {
              let uti: string = transactionResponse.uti;
              return timer(0, 1000).pipe(
                takeUntil(abortTimerSubject),
                switchMap(() => this.orderService.getCheckoutStatusAdmin(orderId, uti).pipe(
                  map(transactionStatus => {
                    console.log(transactionStatus.displayData);
                    if (transactionStatus.transApproved) {
                      abortTimerSubject.next();
                      abortTimerSubject.complete();
                    }
                    if (transactionStatus.transCancelled) {
                      abortTimerSubject.next();
                      abortTimerSubject.complete();
                    }
                    return transactionStatus;
                  })
                  )
                )
              );
            } else {
              // Return error?
              return of();
            }                
          })
        ).subscribe(transactionStatus => {
          this.transactionStatus = transactionStatus;
        });
    }
  }

  abortPaxTransaction() {
    if (this.order?.orderId) {
      this.orderService.abortPaxTransactionAdmin(1)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          console.log('Transaction aborted')
        });
    }
  }

  checkoutOrderCash() {
    let abortTimerSubject: Subject<void> = new Subject();

    if (this.order?.orderId) {
      let orderId: number = this.order?.orderId;
      let payment: PaymentDTO = {
        paymentMethod: {paymentMethodId: 'CASH'},
        transactionType: 'PAYMENT',
        amount: this.order.amountToPay
      };
      this.orderService.checkoutOrderAdmin(orderId, payment)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(orderResponse => {
          console.log(orderResponse);
        });
    }
  }

  checkoutOrderNoPay() {
    if (this.order?.orderId) {
      this.orderService.checkoutOrderNoPayPrivate(this.order?.orderId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(order => {
          // Redirect to confirm page
          this.router.navigateByUrl(`/klarna-confirm?id=${order.orderId}`);
        });
    }
  }

  getSettings() {
    this.orderService.getSettings()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(settings => {
        this.settings = settings
      })
  }

  getRoles() {
    this.orderService.getRoles()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(roles => {
        this.roles = roles
      })
  }

  getUsers() {
    this.orderService.getUsers()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(users => {
        this.users = users
      })
  }
  createUser() {
    this.orderService.createUser({ username: 'new@relyit.se', roles: [{ id: 1, name: 'ROLE_USER' }, { id: 2, name: 'ROLE_ADMIN' }] })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        console.log('user created', user)

        // Set password on user
        if (user.id) {
          this.orderService.setPassword(user.id, { userId: user.id, password: '123456' })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(user => {
              console.log('password updated')
              this.getUsers()
            })
        }
      })
  }
  deleteUser() {
    if (this.users && this.users.content && this.users.content?.length > 0) {
      let userToDelete = this.users.content.filter(user => user.username === 'new@relyit.se')
      if (userToDelete.length > 0 && userToDelete[0].id) {
        this.orderService.deleteUser(userToDelete[0].id)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(user => {
            console.log('user deleted', userToDelete[0])
            this.getUsers()
          })
      }
    }

  }

  getBookings() {
    this.orderService.getBookingsAdmin(0, 5, 'createdDate,desc', '%')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(bookings => {
        this.bookings = bookings;
      });
  }

  getPriceLists() {
    this.orderService.getPriceListsAdmin()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(priceLists => {
        this.priceLists = priceLists;
      });
  }

  getPriceList(priceListId: number | undefined) {
    if (priceListId) {
      this.orderService.getPriceListAdmin(priceListId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(priceList => {
          this.priceList = priceList;
        });
    }
  }

  createPriceList() {
    let priceList: PriceListDTO = 
    {
      "resource": {
        "resourceId": 1,
        "name": "PARKING_GARAGE",
        "active": true,
        "resourceType": "LIMITED_QTY",
        "label": "Garage"
      },
      "validFrom": new Date("2024-01-01T00:00:00"),
      "validTo": undefined,
      "name": "Ny prislista",
      "prices": [
        {
          "days": 1,
          "price": 300,
          "daysOperator": 0
        },
        {
          "days": 2,
          "price": 400,
          "daysOperator": 0
        },
        {
          "days": 3,
          "price": 500,
          "daysOperator": 0
        },
        {
          "days": 4,
          "price": 600,
          "daysOperator": 0
        },
        {
          "days": 5,
          "price": 700,
          "daysOperator": 0
        },
        {
          "days": 6,
          "price": 800,
          "daysOperator": 0
        },
        {
          "days": 7,
          "price": 900,
          "daysOperator": 0
        },
        {
          "days": 8,
          "price": 900,
          "daysOperator": 0
        },
        {
          "days": 9,
          "price": 1000,
          "daysOperator": 0
        },
        {
          "days": 10,
          "price": 1060,
          "daysOperator": 0
        },
        {
          "days": 11,
          "price": 1120,
          "daysOperator": 0
        },
        {
          "days": 12,
          "price": 1180,
          "daysOperator": 0
        },
        {
          "days": 13,
          "price": 1240,
          "daysOperator": 0
        },
        {
          "days": 14,
          "price": 1300,
          "daysOperator": 0
        },
        {
          "days": 15,
          "price": 1300,
          "daysOperator": 0
        },
        {
          "days": 16,
          "price": 1360,
          "daysOperator": 0
        },
        {
          "days": 17,
          "price": 1420,
          "daysOperator": 0
        },
        {
          "days": 18,
          "price": 1480,
          "daysOperator": 0
        },
        {
          "days": 19,
          "price": 1540,
          "daysOperator": 0
        },
        {
          "days": 20,
          "price": 1600,
          "daysOperator": 0
        },
        {
          "days": 21,
          "price": 1660,
          "daysOperator": 0
        },
        {
          "days": 22,
          "price": 1660,
          "daysOperator": 0
        },
        {
          "days": 23,
          "price": 1760,
          "daysOperator": 0
        },
        {
          "days": 24,
          "price": 1860,
          "daysOperator": 0
        },
        {
          "days": 25,
          "price": 1960,
          "daysOperator": 0
        },
        {
          "days": 26,
          "price": 2060,
          "daysOperator": 0
        },
        {
          "days": 27,
          "price": 100,
          "daysOperator": 1
        }
      ],
      "featurePrices": []
    };

    this.orderService.createPriceListAdmin(priceList)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(priceList => {
      this.getPriceLists();
    });

  }

  deletePriceList(priceListId: number | undefined) {
    if (priceListId && priceListId > 3) {
      this.orderService.deletePriceListAdmin(priceListId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.priceList = undefined;
        this.getPriceLists();
      });
    }
  }

  findCurrentFlights() {
    this.orderService.findCurrentFlights('ALL')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(currentFlights => {
        this.currentFlights = currentFlights;
      });
  }

  generateBookings() {
    if (this.generateBookingForm.value.fromDate && this.generateBookingForm.value.toDate) {
      this.orderService.demoGenerateOrders(this.generateBookingForm.value.fromDate, this.generateBookingForm.value.toDate)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        console.log('Bookings generated for', this.generateBookingForm.value.fromDate, this.generateBookingForm.value.toDate);
      });
    }
  }

  getPrepaidTickets() {
    this.orderService.getPrepaidTicketsAdmin(0, 1000)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(prepaidTickets => {
        this.prepaidTickets = prepaidTickets.content;
      });
  }

  getPrepaidTicket(prepaidTicketId: number | undefined) {
    if (prepaidTicketId) {
      this.orderService.getPrepaidTicketAdmin(prepaidTicketId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(prepaidTicket => {
          this.prepaidTicket = prepaidTicket;
        });
    }
  }

  createPrepaidTicket() {
    let prepaidTicket: PrepaidTicketDTO = 
    {
      "name": "TestBiljett",
      "validFrom": new Date("2023-12-31T23:00:00"),
      "validTo": new Date("2024-12-31T23:00:00"),
      "maxPark": 1,
      "cars": [
        {
          "registrationNumber": "ABC123",
        },
        {
          "registrationNumber": "ABC321",
        },
        {
          "registrationNumber": "CBA123",
        },
        {
          "registrationNumber": "CBA321",
        }
      ],
      "comment": 'Rely IT testar',
      "resource": {
        label: "Utomhus",
        name: "PARKING_OUTDOOR",
        resourceId: 3,
        resourceType: "LIMITED_QTY"
      }
    };

    this.orderService.createPrepaidTicketAdmin(prepaidTicket)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(prepaidTicket => {
      this.getPrepaidTickets();
    });

  }

  deletePrepaidTicket(prepaidTicketId: number | undefined) {
    if (prepaidTicketId) {
      this.orderService.deletePrepaidTicketAdmin(prepaidTicketId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.prepaidTicket = undefined;
        this.getPrepaidTickets();
      });
    }
  }

  getFlights() {
    const date = new Date();

    this.orderService.getFlightsAdmin(date.toISOString().substring(0, 10), 'ARRIVAL')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(flight => {
        console.log(flight);
      });
  }
  
  getAvailableResources() {
    const date = dayjs().add(7, 'day');
    let booking: BookingDTO = {
      departureDate: date.toDate(),
      arrivalDate: date.add(5, 'day').toDate()
    };

    this.orderService.getAvailableResources(undefined, undefined, undefined, booking)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(resourceStatus => {
        this.resourceStatus = resourceStatus;
    });
  }

  downloadReportBookings() {
    const date = dayjs();
    this.exportService.getReportBookings(date.toDate(), date.add(7, 'day').toDate())
  }
}
