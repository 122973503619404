<h2 class="no-margin-bottom">Garagebilar</h2>
<p>Bilar i garaget just nu: {{currentlyInGarage}}</p>

<input class="search-input" type="search" placeholder="Sök på reg.nr eller bokningsnr." [formControl]="searchInput"
  (input)="search()" data-test="search-all-bookings" spellcheck="false" autocomplete="off" />

<mat-paginator [length]="totalBookings" [pageIndex]="pageIndex" [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" aria-label="Välj sida" (page)="paginator($event)">
</mat-paginator>

<div class="spinner-wrapper" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>

<app-bookings-table [bookings]="bookings" topic="garage" [sortOrder]="sortOrder" [prevTargetColumn]="sortByColumn"
  (sortColumnClick)="sortColumns($event)">
</app-bookings-table>

<div class="not-found" *ngIf="bookings.length <= 0">
  <p>Kan inte hitta någon bokning<span *ngIf="searchInput.value"> som matchar "{{searchInput.value}}"</span>.</p>
</div>

<mat-paginator [length]="totalBookings" [pageIndex]="pageIndex" [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" aria-label="Välj sida" (page)="paginator($event)">
</mat-paginator>