import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, from, throwError } from 'rxjs';
import { take, mergeMap, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
    
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        ) {
    }
    
    // Intercepts all HTTP requests!
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Only set autorization header on /private/
        if (request.url.indexOf('/private/') > 0 || request.url.indexOf('/admin/') > 0) {
            let token = this.authenticationService.getToken();
            if (request.url.indexOf('/private/') > 0) {
                // replace token with order token
                token = this.authenticationService.getOrderToken();
            }
            if (token) {
                let clonedReq = this.addToken(request, token);
                return next.handle(clonedReq).pipe(
                    catchError(error => {
                        if (error instanceof HttpErrorResponse && error.status === 401 /*&& ! clonedReq.url.includes('/private/users/me')*/) {
                            this.authenticationService.logout();
                            return of(null);
                        } else {
                            return throwError(() => error);
                        }
                    })
                );
            } else {
                // No token, redirect to login page
                this.authenticationService.logout();
                return of(null);
            }
        } else {
            return next.handle(request);
        } 
    }
    
    // Adds the token to your headers if it exists
    private addToken(request: HttpRequest<any>, token: any) {
        if (token) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                setHeaders: {
                    Accept: request.headers.get('Accept') || `application/json`,
                    'Content-Type': request.headers.get('Content-Type') || `application/json`,
                    Authorization: `Bearer ${token}`
                }
            });
            return clone;
        }
  
        return request;
    }
}