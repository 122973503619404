<h2 class="title">Abonnemang</h2>

<div class="prepaid-header">
  <div>
    <input
      type="search"
      matInput
      (input)="searchTicket($event)"
      placeholder="Sök efter abonnemang"
      #search
      autocomplete="off"
      spellcheck="false"
    />
    <mat-checkbox 
      [(ngModel)]="inactiveFilter" 
      (change)="toggleInactive($event.checked)">
      Visa inaktiva
    </mat-checkbox>
  </div>
  
  <button class="button slim with-icon" (click)="createPrepaidTicket()" [disabled]="activeOrder"><mat-icon>add</mat-icon> Nytt abonnemang</button>
</div>

<div>
  <table
    mat-table
    matSort
    (matSortChange)="sortData($event)"
    [dataSource]="dataSource"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let card">{{ card.prepaidTicketId }}</td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Kontonamn</th>
      <td mat-cell *matCellDef="let card">{{ card.name }}</td>
    </ng-container>

    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Startdatum</th>
      <td mat-cell *matCellDef="let card">
        {{ card.validFrom | date : "yyy-MM-dd" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Slutdatum</th>
      <td mat-cell *matCellDef="let card">
        {{ card.validTo | date : "yyy-MM-dd" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="max">
      <th mat-header-cell *matHeaderCellDef>Max samtidiga bilar</th>
      <td mat-cell *matCellDef="let card">{{ card.maxPark }}</td>
    </ng-container>

    <ng-container matColumnDef="amt">
      <th mat-header-cell *matHeaderCellDef>Kopplade bilar</th>
      <td mat-cell *matCellDef="let card">{{ card.cars.length }}</td>
    </ng-container>

    <ng-container matColumnDef="used">
      <th mat-header-cell *matHeaderCellDef>Använt, antal gånger</th>
      <td mat-cell *matCellDef="let card">{{ card.used }}</td>
    </ng-container>

    <ng-container matColumnDef="exp">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let card">
        <mat-icon
          matTooltip="Abonnemang har passerat utgångsdatum"
          *ngIf="hasExpired(card.validTo)"
          class="expired"
        >
          update
        </mat-icon>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="['id', 'owner', 'from', 'to', 'max', 'amt', 'used', 'exp']"
    ></tr>
    <tr
      mat-row
      class="mat-row"
      *matRowDef="
        let rowData;
        columns: ['id', 'owner', 'from', 'to', 'max', 'amt', 'used', 'exp']
      "
      (click)="editPrepaidTicket(rowData)"
    ></tr>
  </table>
</div>
