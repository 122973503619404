import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DefaultService, PartnerDTO } from 'parking-sdk';
import * as Actions from './partner.action';

export interface PartnerStateModel {
  currentPartner?: PartnerDTO;
}

@State<PartnerStateModel>({
  name: 'partner',
  defaults: {
    currentPartner: undefined,
  },
})
@Injectable()
export class PartnerState {
  constructor() {}
  @Selector()
  static currentPartner(state: PartnerStateModel): PartnerDTO | undefined {
    return state.currentPartner;
  }

  @Action(Actions.SetCurrentPartner)
  setCurrentPartner(
    context: StateContext<PartnerStateModel>,
    action: Actions.SetCurrentPartner
  ) {
    context.patchState({ currentPartner: action.partner });
  }

  @Action(Actions.ResetPartnerState)
  resetState(context: StateContext<PartnerStateModel>) {
    context.setState({
      currentPartner: undefined,
    });
  }
}
