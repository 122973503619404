<div class="partnership-report-wrapper">
	<h2>{{partner?.name || 'Namn saknas'}} - partnerrapport</h2>

	<!-- Datepicker -->
	<div class="date-range-wrapper">
		<form *ngIf="dateRangeForm" [formGroup]="dateRangeForm" spellcheck="false" autocomplete="off">
			<mat-form-field>
				<mat-label>Period</mat-label>
				<mat-date-range-input [rangePicker]="partnerBookingReportDatePicker">
					<input matStartDate placeholder="Från" name="from" formControlName="from" />
					<input matEndDate placeholder="Till" name="to" formControlName="to" />
				</mat-date-range-input>
				<mat-hint>ÅÅÅÅ-MM-DD - ÅÅÅÅ-MM-DD</mat-hint>
				<mat-datepicker-toggle matIconSuffix [for]="partnerBookingReportDatePicker"></mat-datepicker-toggle>
				<mat-date-range-picker #partnerBookingReportDatePicker></mat-date-range-picker>
			</mat-form-field>
		</form>

		<button (click)="downloadReportBookings()" class="button slim secondary export"
			title="Exportera till CSV">Exportera<mat-icon>save_alt</mat-icon>
		</button>
	</div>

	<!-- Chart -->
	<section class="chart-section">
		<div class="chart-wrapper" *ngIf="!loading">
			<div class="info-icon">
				<mat-icon>info</mat-icon>
				<div class="show-on-info-icon-hover">
					<h4>Graf </h4>
					<p>Beloppet är summan av det ordinarie priset på bokningarna.</p>
					<ul>
						<li><strong>Utnyttjade bokningar</strong> - endast bokningar som har blivit utnyttjade.</li>
						<li><strong>Alla bokningar</strong> - alla bokningar oavsett om de har blivit utnyttjade eller
							ej.</li>
					</ul>
				</div>
			</div>
			<stat-linechart [chartData]="chartData" [titleArray]="chartTitles" [showTooltip]="true">
			</stat-linechart>
		</div>
	</section>

	<!-- Table -->
	<table *ngIf="report" mat-table [dataSource]="report">
		<!-- Booking number -->
		<ng-container matColumnDef="bookingNumber" class="bookingNumber">
			<th mat-header-cell *matHeaderCellDef>Bokningsnummer</th>
			<td mat-cell *matCellDef="let element">
				{{element.bookingNumber || '-'}}
			</td>
			<td mat-footer-cell *matFooterCellDef>Total </td>
		</ng-container>

		<!-- Booking date -->
		<ng-container matColumnDef="bookingDate" class="bookingDate">
			<th mat-header-cell *matHeaderCellDef>Bokat</th>
			<td mat-cell *matCellDef="let element" [ngClass]="{'total': element.label === 'Total'}">
				{{formatDate(element.bookingDate)}} {{formatTime(element.bookingDate)}}
			</td>
			<td mat-footer-cell *matFooterCellDef>{{getTotalBookings()}} st</td>
		</ng-container>

		<!-- Departure date -->
		<ng-container matColumnDef="departureDate" class="departureDate">
			<th mat-header-cell *matHeaderCellDef>Starttid parkering</th>
			<td mat-cell *matCellDef="let element" [ngClass]="{'total': element.label === 'Total'}">
				{{formatDate(element.departureDate)}} {{formatTime(element.departureDate)}}
			</td>
			<td mat-footer-cell *matFooterCellDef class="number-data"></td>
		</ng-container>

		<!-- Arrival date -->
		<ng-container matColumnDef="arrivalDate" class="arrivalDate">
			<th mat-header-cell *matHeaderCellDef>Sluttid parkering</th>
			<td mat-cell *matCellDef="let element" [ngClass]="{'total': element.label === 'Total'}">
				{{formatDate(element.arrivalDate)}} {{formatTime(element.arrivalDate)}}
			</td>
			<td mat-footer-cell *matFooterCellDef class="number-data"></td>
		</ng-container>

		<!-- Completed -->
		<ng-container matColumnDef="completed" class="completed">
			<th mat-header-cell *matHeaderCellDef>Utnyttjat</th>
			<td mat-cell *matCellDef="let element" [ngClass]="{'total': element.label === 'Total'}">
				{{element.completed ? 'Ja' : 'Nej'}}
			</td>
			<td mat-footer-cell *matFooterCellDef>{{getTotalCompleted()}} st</td>
		</ng-container>

		<!-- Price -->
		<ng-container matColumnDef="price" class="price">
			<th mat-header-cell *matHeaderCellDef class="number-data">Ord. pris</th>
			<td mat-cell *matCellDef="let element" class="number-data" [ngClass]="{'total': element.label === 'Total'}">
				{{element.price | number : "1.0-0"}} kr
			</td>
			<td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('price') | number: "1.0-0"}} kr
			</td>
		</ng-container>

		<!-- Discount -->
		<ng-container matColumnDef="discount" class="discount">
			<th mat-header-cell *matHeaderCellDef class="number-data">Rabatt</th>
			<td mat-cell *matCellDef="let element" class="number-data" [ngClass]="{'total': element.label === 'Total'}">
				-{{element.discount | number : "1.0-0"}} kr <span class="discount-percent">{{element.discountPercent ? '(' + element.discountPercent * 100 + '%)' : ''}}</span>
			</td>
			<td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('discount') > 0 ? '-' : ''
				}}{{getTotalByType('discount') | number: "1.0-0"}}
				kr</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		<tr mat-footer-row
			*matFooterRowDef="['bookingNumber', 'bookingDate', 'departureDate', 'arrivalDate', 'completed', 'price', 'discount']">
		</tr>

	</table>
</div>