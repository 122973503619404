<!-- Searchbox -->
<section class="searchbox-wrapper">
  <input
    type="search"
    placeholder="Reg.nr/bokningsnr."
    [formControl]="searchInput"
    #searchBox
    (input)="search(searchInput.value || undefined)"
    data-test="search-in-list"
    spellcheck="false"
    autocomplete="off"
  />
  <div class="filters" *ngIf="currentDirection">
    <mat-checkbox class="example-margin" color="warn" disabled>Handbagage</mat-checkbox>
    <mat-checkbox class="example-margin" color="warn" disabled>Garage</mat-checkbox>
    <mat-checkbox class="example-margin" color="warn" disabled>KRAV</mat-checkbox>
    <mat-checkbox class="example-margin" color="warn" disabled>Laddbilar</mat-checkbox>
  </div>
</section>

<div class="search-result-wrapper">
  <app-bookings-table
    *ngIf="searchResult && !termNotFound"
    [bookings]="searchResult"
    [topic]="currentDirection"
    [coloredSearchResult]="true"
  ></app-bookings-table>

  <!-- Booking not found -->
  <div class="empty-content" *ngIf="termNotFound && !searchResult">
    <p>Kunde inte hitta en bokning med "{{ termNotFound }}"</p>
  </div>

  <div class="spinner-wrapper" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
