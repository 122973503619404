import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IframeService {
  attachStyles(targetDocument: Document): void {
    // Copy styles from parent window
    document.querySelectorAll('style').forEach((styleElement) => {
      targetDocument.head.appendChild(styleElement.cloneNode(true));
    });
    // Copy stylesheet links from parent window
    document.querySelectorAll('link').forEach((linkElement) => {
      if (linkElement.rel === 'stylesheet') {
        const styleSheetElement = document.createElement('link');
        const absoluteUrl = new URL(linkElement.href).href;
        styleSheetElement.rel = 'stylesheet';
        styleSheetElement.type = 'text/css';
        styleSheetElement.href = absoluteUrl;

        targetDocument.head.appendChild(styleSheetElement);
      }
    });
  }

  addTitle(targetDocument: Document, title: string): void{
    const iframeTitle = document.createElement('title');
    iframeTitle.innerText = title;
    targetDocument.head.appendChild(iframeTitle);
  }
}
