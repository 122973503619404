import { Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  formatDate(date: string | Date | Dayjs): string {
    return dayjs(date).format('YYYY-MM-DD')
  }

  formatTime(date: string | Date | Dayjs): string {
    return dayjs(date).format('HH:mm')
  }

  composeDatetime(
    date: string | Date | undefined,
    time: string | undefined
  ): Date | undefined {
    const times = time?.split(':');
    const result =
      (times && date && dayjs(date).hour(+times[0]).minute(+times[1])) ||
      undefined;

    return result && result.toDate();
  }
}
