import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentDTO } from 'parking-sdk';
import { DateService } from 'src/app/core/services/date.service';

@Component({
  selector: 'app-unfinished-payments',
  templateUrl: './unfinished-payments.component.html',
  styleUrls: ['./unfinished-payments.component.scss'],
})
export class UnfinishedPaymentsComponent {
  @Input() startedPayments?: PaymentDTO[];
  @Output() continuePayment: EventEmitter<string> = new EventEmitter();

  constructor(private dateService: DateService){}

  formatDate(date?: string | Date): string | undefined {
    return date && this.dateService.formatDate(date);
  }

  formatTime(date?: string | Date): string | undefined {
    return date && this.dateService.formatTime(date);
  }

  continue(uti?: string){
    uti && this.continuePayment.emit(uti)
  }
}
