import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DefaultService } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent implements OnInit {
  reqSent: boolean = false;
  email: FormControl = new FormControl('', [Validators.required]);

  private onDestroy$ = new Subject<void>();

  constructor(
    private defaultService: DefaultService, 
    private router: Router) {}

    ngOnInit(): void {
      
    }

  sendResetRequest() {
    this.defaultService.requestPasswordResetAdmin({email: this.email.value,
      resetToken: "", 
      newPassword: ""})
      .pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.reqSent = true;
      });
  }

  toLogin() {
    this.router.navigate(['../home']);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
