<h1>{{ title }}</h1>

<div class="test-block">
    <h2>Klarna</h2>
    <div class="buttons">
        <button *ngIf="order" class="button" (click)="addBooking()">Lägg till parkeringsbokning</button>
        <button *ngIf="order" class="button" (click)="addFeature()">Lägg till tvätt</button>
        <button *ngIf="order" class="button" (click)="addPrepaidTicket()">Lägg till abonnemang</button>
    </div>
    <div class="buttons">
        <button *ngIf="order" class="button" [disabled]="order.orderItems?.length == 0" (click)="checkoutOrderKlarna()">Betala med klarna</button>
        <button *ngIf="order" class="button" [disabled]="order.orderItems?.length == 0" (click)="checkoutOrderPax()">Betala med kort</button>
        <button *ngIf="order" class="button" [disabled]="order.orderItems?.length == 0" (click)="checkoutOrderCash()">Betala med kontanter</button>
        <button *ngIf="order" class="button" [disabled]="order.orderItems?.length == 0" (click)="checkoutOrderNoPay()">Betala senare</button>
    </div>

    <div *ngIf="order">
        OrderId: {{order.orderId}}

        <table class="table">
            <tr>
                <th>BookingId</th>
                <th>RegistrationNumber</th>
                <th>DepartureDate</th>
                <th>ArrivalDate</th>
                <th>Amount</th>
            </tr>
            <tr *ngFor="let orderItem of order.orderItems">
                <ng-container *ngIf="orderItem.orderItemType === 'RESOURCE'">
                    <td>{{orderItem.booking?.bookingId}}</td>
                    <td>{{orderItem.booking?.registrationNumber}}</td>
                    <td>{{orderItem.booking?.departureDate | date}}</td>
                    <td>{{orderItem.booking?.arrivalDate | date}}</td>
                    <td>{{orderItem.amount }}</td>
                </ng-container>
                <ng-container *ngIf="orderItem.orderItemType === 'FEATURE'">
                    <td>{{orderItem.feature?.featureId}}</td>
                    <td colspan="3">{{orderItem.feature?.name}}</td>
                    <td>{{orderItem.amount }}</td>
                </ng-container>
                <ng-container *ngIf="orderItem.orderItemType === 'PREPAIDTICKET'">
                    <td>{{orderItem.prepaidTicket?.prepaidTicketId}}</td>
                    <td colspan="3">{{orderItem.prepaidTicket?.name}}</td>
                    <td>{{orderItem.amount }}</td>
                </ng-container>
            </tr>
            <tr>
                <td colspan="4">Totalt</td>
                <td>{{order.totalAmountWithoutDiscount}}</td>
            </tr>
            <tr>
                <td colspan="4">Discount</td>
                <ng-container *ngIf="order.discountType === 'AMOUNT'">
                    <td>{{order.discount}}</td>
                </ng-container>
                <ng-container *ngIf="order.discountType === 'PERCENT' && order.discount && order.totalAmountWithoutDiscount">
                    <td>{{order.discount * order.totalAmountWithoutDiscount}} ({{order.discount * 100}}%)</td>
                </ng-container>
            </tr>
            <tr>
                <td colspan="4">Att betala</td>
                <td>{{order.totalAmount}}</td>
            </tr>
        </table>
        <div>
            <h2>SMS preview</h2>
            <div [innerHTML]="smsPreview"></div>
        </div>
    </div>

    <mat-card *ngIf="transactionStatus">
        <mat-card-content>
            <ul>
                <li *ngFor="let statusValue of transactionStatus.displayData">
                    {{statusValue.description}}
                </li>
            </ul>
            <button class="button" (click)="abortPaxTransaction()">Avbryt transaktion</button>
        </mat-card-content>
    </mat-card>
    
    <!-- <pre><p>{{order | json}}</p></pre> -->
    <div *ngIf="klarnaSnippet" [innerHTML]="klarnaSnippet | keepHtml" runScripts>
    </div>
</div>

<div class="test-block">
    <h2>Settings</h2>
    <button class="button" (click)="getSettings()">Hämta settings</button>
    <pre *ngIf="settings">
        {{settings | json}}
    </pre>
</div>

<div class="test-block">
    <h2>Roles</h2>
    <button class="button" (click)="getRoles()">Hämta roles</button>
    <pre *ngIf="roles">
        {{roles | json}}
    </pre>
</div>

<div class="test-block">
    <h2>Users</h2>
    <div class="buttons">
        <button class="button" (click)="getUsers()">Hämta users</button>
        <button class="button" (click)="createUser()">Skapa user</button>
        <button class="button" (click)="deleteUser()">Radera user</button>
    </div>
    <pre *ngIf="users">
        {{users | json}}
    </pre>
</div>

<div class="test-block">
    <h2>Bookings</h2>
    <button class="button" (click)="getBookings()">Hämta bookings</button>

    <table *ngIf="bookings" class="table">
        <tr>
            <th>Bokningsnummer</th>
            <th>Namn</th>
            <th>Reg.nummer</th>
            <th>Destination</th>
            <th>Datum</th>
            <th>Flight</th>
        </tr>

        <tr *ngFor="let booking of bookings.content">
            <td>{{booking.qr}}</td>
            <td>{{booking.name}}</td>
            <td>{{booking.registrationNumber}}</td>
            <td>{{booking.departureFlight?.arrivalAirport}}</td>
            <td>{{booking.departureDate | date}} - {{booking.arrivalDate | date}}</td>
            <td>{{booking.departureFlight?.flightNumber}} - {{booking.arrivalFlight?.flightNumber}}</td>
        </tr>

    </table>
</div>

<div class="test-block">
    <h2>Prislistor</h2>
    <div class="buttons">
        <button class="button" (click)="getPriceLists()">Hämta prislistor</button>
        <button class="button" (click)="createPriceList()">Skapa prislista</button>
        <button class="button" (click)="deletePriceList(priceList?.priceListId)" [disabled]="!priceList">Radera vald prislista</button>
    </div>

    <table *ngIf="priceLists" class="table">
        <tr>
            <th>Id</th>
            <th>Namn</th>
            <th>Typ</th>
            <th>Datum</th>
        </tr>

        <tr *ngFor="let priceList of priceLists" class="clickable" (click)="getPriceList(priceList.priceListId)">
            <td>{{priceList.priceListId}}</td>
            <td>{{priceList.name}}</td>
            <td>{{priceList.resource?.label}}</td>
            <td>{{priceList.validFrom | date}} - {{priceList.validTo | date}}</td>
        </tr>
    </table>

    <table *ngIf="priceList" class="table">
        <ng-container *ngIf="priceList.prices && priceList.prices.length > 0">
            <tr>
                <th>Dag</th>
                <th>Pris</th>
                <th>DaysOperator</th>
            </tr>
    
            <tr *ngFor="let price of priceList.prices">
                <td>{{price.days}}</td>
                <td>{{price.price}}</td>
                <td>{{price.daysOperator}}</td>
            </tr>
        </ng-container>
        <ng-container *ngIf="priceList.featurePrices && priceList.featurePrices.length > 0">
            <tr>
                <th>Typ</th>
                <th>Pris</th>
            </tr>
    
            <tr *ngFor="let featurePrice of priceList.featurePrices">
                <td>{{featurePrice.feature?.name}}</td>
                <td>{{featurePrice.price}}</td>
            </tr>
        </ng-container>
    </table>
</div>

<div class="test-block">
    <h2>Current Flights</h2>
    <button class="button" (click)="findCurrentFlights()">Hämta current flights</button>

    <table *ngIf="currentFlights" class="table">
        <tr>
            <th>Flightnummer</th>
            <th>FlightType</th>
            <th>Date</th>
            <th>Antal bokningar</th>
        </tr>

        <tr *ngFor="let currentFlight of currentFlights">
            <td>{{currentFlight.flightNumber}}</td>
            <td>{{currentFlight.flightType }}</td>
            <td *ngIf="currentFlight.flightType === 'DEPARTURE'">{{currentFlight.departureDate | date : 'yyyy-MM-dd HH:mm'}} {{currentFlight.departureDateType }}{{currentFlight.arrivalDate | date : 'yyyy-MM-dd HH:mm'}} {{currentFlight.arrivalDateType }}</td>
            <td *ngIf="currentFlight.flightType === 'ARRIVAL'">{{currentFlight.arrivalDate | date : 'yyyy-MM-dd HH:mm'}} {{currentFlight.arrivalDateType }}</td>
            <td>{{currentFlight.bookings?.length}}</td>
        </tr>

    </table>
</div>

<div class="test-block">
    <h2>Generera bokningar</h2>

    <form [formGroup]="generateBookingForm" (ngSubmit)="generateBookings()">
        <label aria-label="Från datum">
            <input formControlName="fromDate" id="fromDate" type="text" placeholder="YYYY-MM-DD">
        </label>
        <label aria-label="Till datum">
            <input formControlName="toDate" id="toDate" type="text" placeholder="YYYY-MM-DD">
        </label>
        <button class="button" type="submit" [disabled]="!generateBookingForm.valid">
            Generera
        </button>
    </form>
</div>

<div class="test-block">
    <h2>Årskort</h2>
    <div class="buttons">
        <button class="button" (click)="getPrepaidTickets()">Hämta årskort</button>
        <button class="button" (click)="createPrepaidTicket()">Skapa årskort</button>
        <button class="button" (click)="deletePrepaidTicket(prepaidTicket?.prepaidTicketId)" [disabled]="!prepaidTicket">Radera valt årskort</button>
    </div>

    <table *ngIf="prepaidTickets" class="table">
        <tr>
            <th>Id</th>
            <th>Namn</th>
            <th>MaxPark</th>
            <th>Datum</th>
        </tr>

        <tr *ngFor="let prepaidTicket of prepaidTickets" class="clickable" (click)="getPrepaidTicket(prepaidTicket.prepaidTicketId)">
            <td>{{prepaidTicket.prepaidTicketId}}</td>
            <td>{{prepaidTicket.name}}</td>
            <td>{{prepaidTicket.maxPark}}</td>
            <td>{{prepaidTicket.validFrom | date}} - {{prepaidTicket.validTo | date}}</td>
        </tr>
    </table>

    <table *ngIf="prepaidTicket" class="table">
        <ng-container *ngIf="prepaidTicket.cars && prepaidTicket.cars.length > 0">
            <tr>
                <th>Registreringsnummer</th>
            </tr>
    
            <tr *ngFor="let car of prepaidTicket.cars">
                <td>{{car.registrationNumber}}</td>
            </tr>
        </ng-container>
    </table>
</div>

<div class="test-block">
    <h2>Flights</h2>
    <div class="buttons">
        <button class="button" (click)="getFlights()">Hämta flight</button>
    </div>    
    <!-- <pre *ngIf="users">
        {{users | json}}
    </pre> -->
</div>


<div class="test-block">
    <h2>AvailableResources</h2>
    <button class="button" (click)="getAvailableResources()">Hämta resources</button>
    <pre *ngIf="settings">
        {{settings | json}}
    </pre>
</div>

<div class="test-block">
    <h2>Export</h2>
    <button class="button" (click)="downloadReportBookings()">Download reportBookings</button>
</div>