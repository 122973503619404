<aside>
    <nav class="side-menu">
        <ul>
            <li class="new-booking-sidemenu mat-icon-button" [ngClass]="{'active-order': activeOrder}" title="Ny bokning" [routerLink]="activeOrder ? '' :'/ny-bokning'"
                routerLinkActive="active" (click)="close()">
                <mat-icon aria-hidden="false" aria-label="Ny bokning" fontIcon="add"></mat-icon>
            </li>
            <li class="search-sidemenu mat-icon-button {{drawerActive && currentContent ==='search' ? 'active' : null}}"
                title="Sök bland alla bokningar" (click)="changeDrawer('search')">
                <mat-icon aria-hidden="false" aria-label="Sök bland alla bokningar" fontIcon="search"></mat-icon>
            </li>
            <li class="register-sidemenu mat-icon-button {{drawerActive && currentContent ==='register' ? 'active' : null}}"
                title="Kassa" (click)="changeDrawer('register')">
                <mat-icon aria-hidden="false" aria-label="Kassa" fontIcon="attach_money"></mat-icon>
            </li>
        </ul>
    </nav>
</aside>