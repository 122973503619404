<div mat-dialog-content class="dialog">
  <h3>{{editMode ?"Redigera abonnemang" : "Skapa nytt abonnemang"}}</h3>
  <form *ngIf="prepaidForm" [formGroup]="prepaidForm" spellcheck="false" autocomplete="off">
    <div class="form-fieldset">
      <!-- Ticket name -->
      <mat-form-field>
        <mat-label>Kontonamn</mat-label>
        <input matInput name="name" formControlName="name" required spellcheck="false"
          (change)="updatePrepaidTicket()" />
      </mat-form-field>

      <!-- Amount of cars -->
      <mat-form-field>
        <mat-label>Max samtidiga bilar</mat-label>
        <input matInput type="number" name="maxCars" formControlName="maxCars" required (change)="updatePrepaidTicket()" />
      </mat-form-field>
    </div>

    <!-- Dates -->
    <mat-form-field>
      <mat-label>Giltighetstid</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate name="validFrom" formControlName="validFrom" placeholder="Startdatum"
          (dateChange)="updatePrepaidTicket()" />
        <input matEndDate name="validTo" formControlName="validTo" placeholder="Slutdatum"
          (dateChange)="updatePrepaidTicket()" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <div class="form-fieldset">
      <!-- Price -->
      <mat-form-field>
        <mat-label>Pris</mat-label>
        <input matInput type="number" name="amount" formControlName="amount" (change)="updatePrepaidTicket()" />
      </mat-form-field>

      <!-- Comment -->
      <mat-form-field>
        <mat-label>Kommentar</mat-label>
        <input matInput name="comment" formControlName="comment" (change)="updatePrepaidTicket()" />
      </mat-form-field>
    </div>

    <!-- Features -->
    <div class="form-fieldset types">
      <div>
        <h4>Parkeringstyp</h4>
        <mat-radio-group name="resource" formControlName="resource" (change)="updatePrepaidTicket()">
          <mat-radio-button color="primary" *ngFor="let resource of resourceOptions" [value]="resource"
            [checked]="resource.resourceId === prepaidTicket?.resource?.resourceId">
            {{resource.label}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Active -->
      <div *ngIf="editMode">
        <h4>Aktivt</h4>
        <mat-checkbox color="primary" name="active" formControlName="active" (change)="updatePrepaidTicket()"></mat-checkbox>
      </div>
    </div>

    <!-- Included cars -->
    <h4>Inkluderade bilar</h4>
    <div class="included-cars-wrapper">
      <div class="included-cars" *ngIf="prepaidTicket?.cars?.length">
        <!-- Registration numbers -->
        <ul>
          <li *ngFor="let car of prepaidTicket?.cars; let i = index">
            <mat-icon>directions_car</mat-icon>
            <span>{{car?.registrationNumber}}</span>
            <mat-icon class="remove-discount" title="Ta bort registreringsnummer"
              (click)="removeCar(prepaidTicket?.cars?.[i])">clear</mat-icon>
          </li>
        </ul>
      </div>

      <div class="form-fieldset">
        <mat-form-field class="registration-number">
          <mat-label class="label">Registreringsnummer</mat-label>
          <input matInput name="car" formControlName="car" />
          <!-- Add button -->
        </mat-form-field>
        <button title="Lägg till registreringsnummer" class="add-btn button slim"
          (click)="updatePrepaidTicket(); clearCarInput()">
          lägg till
        </button>
      </div>
    </div>

    <div class="invalid-message" *ngIf="invalidFormMessage">
      <mat-icon>error</mat-icon> <span>{{ invalidFormMessage }}</span>
      <mat-icon>error</mat-icon>
    </div>

    <div mat-dialog-actions class="action-buttons">
      <button mat-button (click)="onCancelClick()">Avbryt</button>
      <div>
        <button class="delete" mat-button *ngIf="!data.create" (click)="onRemoveClick()">Ta bort</button>
        <button mat-raised-button (click)="onCreateClick()" color="primary">
          Spara
        </button>
      </div>
    </div>
  </form>
</div>