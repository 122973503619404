import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import * as Actions from './prepaid.actions';
import { DefaultService, PrepaidTicketDTO } from "parking-sdk";
import { tap } from "rxjs";

export interface PrepaidStateModel {
    tickets: PrepaidTicketDTO[];
}

@State<PrepaidStateModel>({
    name: 'prepaidticket',
    defaults: {
        tickets: []
    }
})
@Injectable()
export class PrepaidState {
    constructor(private defaultService: DefaultService) {}

    @Selector()
    static currentTickets(state: PrepaidStateModel) {
        return state.tickets;
    }

    @Action(Actions.GetPrepaidTickets)
    getPrepaidTickets(context: StateContext<PrepaidStateModel>) {
        return this.defaultService.getPrepaidTicketsAdmin().pipe(
            tap((tickets) => {
                context.patchState({tickets: tickets.content});
            })
        );
    }

    @Action(Actions.AddPrepaidTicket)
    addPrepaidTicket(context: StateContext<PrepaidStateModel>, action: Actions.AddPrepaidTicket) {
        const { ticket } = action;
        return this.defaultService.createPrepaidTicketAdmin(ticket).pipe(
            tap((newTicket) => {
                context.patchState({
                    tickets: [...context.getState().tickets, newTicket]
                });
            })
        );
    }

    @Action(Actions.UpdatePrepaidTicket)
    updatePrepaidTicket(context: StateContext<PrepaidStateModel>, action: Actions.UpdatePrepaidTicket) {
        const { ticketId, ticket } = action;

        return this.defaultService.updatePrepaidTicketAdmin(ticketId, ticket).pipe(
            tap(() => {
                const currentTickets = context.getState().tickets;
                const updatedTickets = currentTickets.map((curTicket) =>
                    curTicket.prepaidTicketId === ticketId ? { ...curTicket, ticket } : curTicket
                );

                context.patchState({
                    tickets: updatedTickets,
                });
            })
        );
    }

    @Action(Actions.DeletePrepaidTicket)
    deletePrepaidTicket(context: StateContext<PrepaidStateModel>, action: Actions.DeletePrepaidTicket) {
        const { ticketId } = action;

        return this.defaultService.deletePrepaidTicketAdmin(ticketId).pipe(
            tap(() => {
                context.patchState({
                    tickets: context.getState().tickets.filter((ticket) => ticket.prepaidTicketId !== ticketId)
                });
            })
        )
    }

}