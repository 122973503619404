import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import dayjs from 'dayjs';
import { BookingDTO } from 'parking-sdk';
import { Subject, debounceTime, map, takeUntil } from 'rxjs';
import {
  BookingSearchListener,
  GetAllBookings,
} from 'src/app/core/states/bookings/booking.actions';
import { BookingsState } from 'src/app/core/states/bookings/bookings.state';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent {
  startDate = new FormControl<Date | undefined>(
    dayjs().add(-1, 'year').toDate(),
    { nonNullable: true }
  );
  endDate = new FormControl<Date | undefined>(undefined, { nonNullable: true });
  filterDateOn = new FormControl<'arrival' | 'departure'>('arrival');
  bookings: BookingDTO[] = [];
  dispatchBookingsErrorCode?: any;
  totalBookings: number = 0;
  pageIndex: number = 0;
  pageSize: number = 50;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  sortByColumn?: string;
  sortOrder?: 'asc' | 'desc' = 'desc';
  searchInput = new FormControl<string | undefined>(undefined);
  filterCheckboxes = new FormGroup({
    resource: new FormControl<string>('all'),
    prepaid: new FormControl<boolean>(false),
    electric: new FormControl<boolean>(false),
    carWash: new FormControl<boolean>(false),
    tireShop: new FormControl<boolean>(false),
    carSeat: new FormControl<boolean>(false),
  });
  garageCheckbox = new FormControl<boolean>(false);
  garageFilter?: string;
  filters: {
    resource: string | undefined;
    prepaid: string | undefined;
    charge: string | undefined;
    carWash: string | undefined;
    tireShop: string | undefined;
    carSeat: string | undefined;
  } = {
    resource: undefined,
    prepaid: undefined,
    charge: undefined,
    carWash: undefined,
    tireShop: undefined,
    carSeat: undefined,
  };
  private onDestroy$ = new Subject<void>();
  showSpinner: boolean = false;

  constructor(private store: Store, actions$: Actions) {
    actions$
      .pipe(
        ofActionDispatched(BookingSearchListener),
        map((action: BookingSearchListener) => action.term),
        debounceTime(300),
        takeUntil(this.onDestroy$)
      )
      .subscribe((term) => {
        this.dispatchGetAllBookings(term).pipe(takeUntil(this.onDestroy$)).subscribe(
          () => (this.showSpinner = false)
        );
      });
  }

  ngOnInit() {
    this.dispatchGetAllBookings();
    this.store.select(BookingsState.allBookings).pipe(takeUntil(this.onDestroy$)).subscribe((v) => {
      this.bookings = v?.content ? v.content : [];
      this.totalBookings = v?.totalElements ? v.totalElements : 0;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  paginator(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.dispatchGetAllBookings();
  }

  sortColumns(event: { column?: string; sort?: 'asc' | 'desc' }) {
    const { column, sort } = event;
    this.sortByColumn = column;
    this.sortOrder = sort;
    const searchTerm = this.searchInput.value
      ? this.searchInput.value
      : undefined;
    this.dispatchGetAllBookings(searchTerm);
  }

  changeDateFilter(value: 'arrival' | 'departure') {
    this.filterDateOn.setValue(value);
    if (this.searchInput.value) {
      this.dispatchGetAllBookings(this.searchInput.value);
    } else {
      this.dispatchGetAllBookings();
    }
  }

  search() {
    if (
      this.searchInput.value == undefined ||
      !this.searchInput.value.trim().length
    ) {
      this.showSpinner = false;
      this.dispatchGetAllBookings();
    } else {
      this.showSpinner = true;
      this.store.dispatch(new BookingSearchListener(this.searchInput.value));
    }
  }

  filter() {
    this.filters.resource =
      this.filterCheckboxes.controls.resource.value === 'garage'
        ? 'PARKING_GARAGE'
        : this.filterCheckboxes.controls.resource.value === 'outdoor'
        ? 'PARKING_OUTDOOR'
        : undefined;
    this.pageIndex = 0;
    const searchTerm = this.searchInput.value
      ? this.searchInput.value
      : undefined;
    this.dispatchGetAllBookings(searchTerm);
  }

  dispatchGetAllBookings(searchTerm?: string) {
    let endDate = this.endDate.value
      ? dayjs(this.endDate.value).add(1, 'day').toDate()
      : undefined;
    return this.store.dispatch(
      new GetAllBookings(
        this.pageIndex,
        this.pageSize,
        this.startDate.value!,
        endDate,
        this.filterDateOn.value!,
        this.sortByColumn,
        this.sortOrder,
        searchTerm,
        this.filters.resource
      )
    );
  }
}
