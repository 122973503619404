import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';
import {
  DefaultService,
  OrderDTO,
  OrderItemDTO,
  PaymentDTO,
} from 'parking-sdk';
import { Subject, first, takeUntil } from 'rxjs';
import { CashPaymentComponent } from 'src/app/components/payment/cash-payment/cash-payment.component';
import { KlarnaRefundComponent } from 'src/app/components/payment/klarna-refund/klarna-refund.component';
import { ManualRefundComponent } from 'src/app/components/payment/manual-refund/manual-refund.component';
import { PaxPaymentComponent } from 'src/app/components/payment/pax-payment/pax-payment.component';
import { DateService } from 'src/app/core/services/date.service';
import { OrdersState } from 'src/app/core/states/orders/orders.state';
import { ContinuePaxPaymentComponent } from 'src/app/components/payment/continue-pax-payment/continue-pax-payment.component';
import {
  GetOrder,
  ResetOrderTransState,
} from 'src/app/core/states/orders/orders.action';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss'],
})
export class RefundComponent implements OnDestroy {
  order: OrderDTO | undefined;
  refundItem: FormControl = new FormControl<OrderItemDTO | undefined>(
    undefined
  );
  paymentFinished: boolean = true;
  newOrder: boolean = false;
  allowPayments = true;

  bookingId?: number;
  prepaidExists: boolean = false;
  refundExists: boolean = false;
  startedPayments?: PaymentDTO[];

  @ViewChild('iframe', { read: ElementRef, static: false }) iframe:
    | ElementRef
    | undefined;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private defaultService: DefaultService,
    private dateService: DateService
  ) {
    this.store
      .select(OrdersState.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((curOrder) => {
        this.order = curOrder;
        this.order.payments?.forEach((payment) => {
          if (payment.transactionType === 'REFUND') {
            this.refundExists = true;
          }
        });
        this.order.orderItems?.forEach((item) => {
          if (item.booking && !this.bookingId) {
            this.bookingId = item.booking.bookingId;
          }
          if (item.prepaidTicket) {
            this.prepaidExists = true;
          }
        });
      });
  }

  ngOnInit() {
    this.getUnfinishedPayments();
    this.reloadOrder();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  reloadOrder() {
    this.order?.orderId &&
      this.store.dispatch(new GetOrder(this.order.orderId));
  }

  refundPayment(payment: PaymentDTO) {
    this.paymentFinished = false;
    const maxRefund = (this.order?.amountToPay || 0) * -1;

    // Refund for cash
    if (payment.paymentMethod!.paymentMethodId === 'CASH') {
      const dialogRef = this.dialog.open(CashPaymentComponent, {
        width: '30vw',
        data: {
          order: this.order,
          refund: payment,
          maxRefund: maxRefund,
          orderItemId: this.refundItem.value.orderItemId,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result?.success) {
            this.paymentFinished = true;
            this.refundItem.setValue(undefined);
            if (result.receipt) this.printReceipt();
            this.snackBar.open('Återbetalning genomförd', 'OK', {
              duration: 3000,
            });
          }
        });
    }
    // Refund for card
    else if (payment.paymentMethod?.paymentMethodId === 'PAX') {
      const dialogRef = this.dialog.open(PaxPaymentComponent, {
        width: '30vw',
        data: {
          order: this.order,
          refund: payment,
          maxRefund: maxRefund,
          orderItemId: this.refundItem.value.orderItemId,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result.success) {
            this.order?.orderId &&
              this.store
                .dispatch(new GetOrder(this.order.orderId))
                .pipe(first())
                .subscribe(() => {
                  if (result.receipt) {
                    this.printReceipt();
                  }
                });
            this.paymentFinished = true;
            this.refundItem.setValue(undefined);
            this.snackBar.open('Återbetalning genomförd', 'OK', {
              duration: 3000,
            });
          } else if (result && result.cancelled) {
            this.snackBar.open('Order avbruten', 'OK', { duration: 3000 });
          } else if (result && result.declined) {
            this.snackBar.open('Medges ej - prova igen', 'OK', {
              duration: 10000,
            });
          } else if (result?.unavailable) {
            this.snackBar.open('Kunde inte ansluta till terminalen', 'OK', {
              duration: 10000,
            });
          }
        });
    }
    // Refund for Klarna
    else if (payment.paymentMethod?.paymentMethodId === 'KLARNA') {
      const dialogRef = this.dialog.open(KlarnaRefundComponent, {
        width: '30vw',
        data: {
          order: this.order,
          refund: payment,
          maxRefund: maxRefund,
          orderItemId: this.refundItem.value.orderItemId,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result.success) {
            this.paymentFinished = true;
            this.refundItem.setValue(undefined);
            this.snackBar.open('Återbetalning genomförd', 'OK', {
              duration: 3000,
            });
          } else {
            this.snackBar.open('Någonting gick fel, försök igen', 'OK', {
              duration: 3000,
            });
          }
        });
    } else if (
      payment.paymentMethod?.paymentMethodId === 'BANK' ||
      payment.paymentMethod?.paymentMethodId === 'INVOICE'
    ) {
      const dialogRef = this.dialog.open(ManualRefundComponent, {
        width: '30vw',
        data: {
          order: this.order,
          refund: payment,
          maxRefund: maxRefund,
          orderItemId: this.refundItem.value.orderItemId,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result.success) {
            this.reloadOrder();
            this.paymentFinished = true;
            this.refundItem.setValue(undefined);
            this.snackBar.open('Återbetalning genomförd', 'OK', {
              duration: 3000,
            });
          } else {
            this.snackBar.open('Återbetalning avbruten', 'OK', {
              duration: 3000,
            });
          }
        });
    }
  }

  getUnfinishedPayments() {
    this.order?.orderId &&
      this.defaultService
        .getUnfinishedPayments(this.order.orderId)
        .pipe(first())
        .subscribe((response) => {
          if (response?.length) {
            this.startedPayments = response;
            this.allowPayments = false;
          } else {
            this.startedPayments = undefined;
            this.allowPayments = true;
          }
        });
  }

  continuePayment(uti?: string) {
    if (this.order?.orderId) {
      let dialogRef = this.dialog.open(ContinuePaxPaymentComponent, {
        width: '30vw',
        data: { orderId: this.order.orderId, uti },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result && result.approved && this.order?.orderId) {
            this.store
              .dispatch(new GetOrder(this.order.orderId))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                this.snackBar.open('Betalning behandlad', 'OK', {
                  duration: 3000,
                });
                this.getUnfinishedPayments();
              });
          } else if (result?.noConnection) {
            this.allowPayments = true;
            this.snackBar.open('Kunde inte ansluta till terminalen', 'OK', {
              duration: 10000,
            });
          } else {
            this.order?.orderId &&
              this.store
                .dispatch(new GetOrder(this.order.orderId))
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(() => {
                  this.getUnfinishedPayments();
                });
          }
        });
    }
  }

  isDisabled(item: OrderItemDTO): boolean {
    return item.amountPaid === 0;
  }

  formatDate(date?: string | Date): string | undefined {
    return date && this.dateService.formatDate(date);
  }

  formatTime(date?: string | Date): string | undefined {
    return date && this.dateService.formatTime(date);
  }

  awaitReceiptChanges(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.cdr.detectChanges();
      setTimeout(() => {
        resolve();
      }, 0);
    });
  }

  printReceipt() {
    this.defaultService
      .addCashDrawerActionAdmin({
        cashDrawerActionType: 'PAYMENT',
        comment: 'Refund',
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();

    let latestPayment =
      (this.order?.payments?.length &&
        this.order.payments[this.order.payments.length - 1]) ||
      undefined;

    latestPayment?.paymentId &&
      this.defaultService
        .getPaymentReceiptHtmlAdmin(latestPayment.paymentId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((v) => {
          if (v.html) {
            if (this.iframe) {
              this.iframe.nativeElement.contentWindow.document.open();
              this.iframe.nativeElement.contentWindow.document.close();
              this.iframe.nativeElement.contentWindow.frames.document.body.insertAdjacentHTML(
                'afterbegin',
                v.html
              );
              this.awaitReceiptChanges().then(() => {
                this.iframe?.nativeElement.contentWindow.print();
              });
            }
          }
        });
  }
}
