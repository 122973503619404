import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { OrderDTO, PaymentDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import {
  CheckoutOrderCash,
  GetOrder,
  Refund,
  ResetOrderState,
} from 'src/app/core/states/orders/orders.action';

@Component({
  selector: 'app-cash-payment',
  templateUrl: './cash-payment.component.html',
  styleUrls: ['./cash-payment.component.scss'],
})
export class CashPaymentComponent implements OnInit {
  receivedAmount = new FormControl<number | undefined>(
    this.data.order.amountToPay,
    [Validators.required, Validators.min(0)]
  );
  toReturn: number = 0;
  refundComment = new FormControl('', Validators.required);
  amountEntered = false;
  transactionSuccess = false;
  loading = false
  private onDestroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialogRef<CashPaymentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      order: OrderDTO;
      refund: PaymentDTO;
      maxRefund: number;
      orderItemId: number;
    },
    private store: Store
  ) {
    dialog.disableClose = true;
    if (this.data.maxRefund) {
      this.receivedAmount.setValue(this.data.maxRefund);
    }
  }

  ngOnInit(): void {
    if (this.data.refund) {
      this.receivedAmount.addValidators(Validators.max(this.data.maxRefund));
    }
  }

  enterAmount() {
    this.loading = true;
    // Payment
    if (!this.data.refund) {
      let recievedAmount = this.receivedAmount.value;

      if (recievedAmount !== undefined && recievedAmount !== null) {
        let paidAmount = recievedAmount;

        /* Set payment to max amout to pay */
        if (paidAmount > this.data.order.amountToPay!) {
          paidAmount = this.data.order.amountToPay!;
        }

        let payment: PaymentDTO = {
          paymentMethod: { paymentMethodId: 'CASH' },
          transactionType: 'PAYMENT',
          amount: paidAmount,
        };

        this.store
          .dispatch(new CheckoutOrderCash(this.data.order.orderId!, payment))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.amountEntered = true;
            this.transactionSuccess = true;
            if (recievedAmount)
              this.toReturn = recievedAmount - this.data.order.amountToPay!;
            if (this.toReturn < 0) {
              this.toReturn = 0;
            }
            this.loading = false
          });
      }
    }
    // Refund
    else {
      if (
        this.receivedAmount.value! <= this.data.refund.amount! &&
        this.receivedAmount.value! <= this.data.maxRefund &&
        this.refundComment.value !== ''
      ) {
        const payment: PaymentDTO = {
          paymentMethod: this.data.refund.paymentMethod,
          transactionType: 'REFUND',
          amount: this.receivedAmount.value!,
          comment: this.refundComment.value!,
        };

        this.store
          .dispatch(
            new Refund(
              this.data.order.orderId!,
              this.data.orderItemId,
              this.data.refund.paymentId!,
              payment
            )
          )
          .subscribe(() => {
            this.store.dispatch(new ResetOrderState());
            this.data.order.orderId &&
              this.store
                .dispatch(new GetOrder(this.data.order.orderId))
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((v) => {
                  this.amountEntered = true;
                  this.transactionSuccess = true;
                  this.loading = false
                });
          });
      } else {
        // TODO: Better error handling
        console.error(
          'Entered amount is too high. Refund can only be done for amounts smaller than the initial payment and within the range of the total amount paid.'
        );
      }
    }
  }

  closeDialog() {
    if (this.transactionSuccess) {
      this.dialog.close({ success: true, paidAmt: this.receivedAmount.value! });
    } else {
      this.dialog.close();
    }
  }

  closeAndPrintReceipt() {
    this.dialog.close({
      success: true,
      receipt: true,
      paidAmt: this.receivedAmount.value!,
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
