import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { DefaultService, PaymentMethodReportDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-daily-payment-method-report',
  templateUrl: './daily-payment-method-report.component.html',
  styleUrls: ['./daily-payment-method-report.component.scss']
})
export class DailyPaymentMethodReportComponent {
  paymentMethodReport?: PaymentMethodReportDTO[];
  startDate = new FormControl(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );
  endDate = new FormControl(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );

  private onDestroy$ = new Subject<void>();

  constructor(
    private defaultService: DefaultService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.getArticleReport();
  }

  ngAfterViewInit() {
    this.startDate.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.getArticleReport());

    this.endDate.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.getArticleReport());
  }

  getArticleReport(): void {
    this.startDate.value &&
      this.endDate.value &&
      this.defaultService.getPaymentMethodReport(
        dayjs(this.startDate.value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        dayjs(this.endDate.value)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(1, 'day')
          .toDate()
      )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((value) => {
          this.paymentMethodReport = value;
        });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  downloadReport() {
    if (this.startDate.value && this.endDate.value)
      this.exportService.getReportPaymentMethods(
        dayjs(this.startDate.value)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toDate(),
      dayjs(this.endDate.value)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(1, 'day')
        .toDate()
      );
  }

  getTotalPayments() {
    return this.paymentMethodReport?.map(t => t.payments).reduce((acc, value) => (acc || 0) + (value || 0), 0) || 0;
  }
  getTotalRefunds() {
    return this.paymentMethodReport?.map(t => t.refunds).reduce((acc, value) => (acc || 0) + (value || 0), 0) || 0;
  }
  getTotalTotal() {
    return this.paymentMethodReport?.map(t => t.total).reduce((acc, value) => (acc || 0) + (value || 0), 0) || 0;
  }
}
