import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DefaultService, PaymentReportDTO } from 'parking-sdk';
import { BarChartDataset } from 'rely-statistics-sdk';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss'],
})
export class PaymentReportComponent implements OnDestroy {
  startDate = new FormControl(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7,
      0,
      0,
      1
    )
  );
  endDate = new FormControl(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      23,
      59,
      59
    )
  );
  paymentReport: PaymentReportDTO[] = [];
  chartDataSet: BarChartDataset[] = [];
  chartTitles: string[] = [];
  chartColors: string[] = ['#b1ceab', '#f6a200'];

  loadingData = false;
  setEmpty = true;

  private onDestroy$ = new Subject<void>();

  constructor(private defaultService: DefaultService) {
    this.updateData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  updateData() {
    if (this.endDate.value && this.startDate.value) {
      this.endDate.value.setHours(23);
      this.endDate.value.setMinutes(59);
      this.loadingData = true;
      this.setEmpty = true;
      this.chartDataSet = [];
      this.chartTitles = [];
      this.paymentReport = [];

      this.defaultService
        .getPaymentReport(this.startDate.value!, this.endDate.value!)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((reports) => {
          this.paymentReport = [...reports];
          let payments: number[] = [],
            refunds: number[] = [];

          reports.forEach((report) => {
            if (report.payments! > 0 || report.refunds! > 0) {
              this.setEmpty = false;
            }
            payments.push(report.payments!);
            refunds.push(report.refunds!);
            this.chartTitles.push(report.label!);
          });

          this.chartDataSet.push({
            data: payments,
            type: 'bar',
            name: 'Betalningar',
          });
          this.chartDataSet.push({
            data: refunds,
            type: 'bar',
            name: 'Återbetalningar',
          });
          this.loadingData = false;
        });
    }
  }

  getTotalByType(type: 'payments' | 'refunds'): number {
    return (
      this.paymentReport
        ?.map((t) => t[type])
        .reduce((acc, value) => (acc || 0) + (value || 0), 0) || 0
    );
  }
}
