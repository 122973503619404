<h2>Schema</h2>

<!-- Button to decrease selected date -->
<button mat-icon-button (click)="goPrevDate()"><mat-icon>chevron_left</mat-icon></button>

<!-- Date picker - specific date -->
<mat-form-field>
  <mat-label>Välj datum</mat-label>
  <input matInput [matDatepicker]="picker" [(ngModel)]="selectedFromDate" (dateInput)="formatDates()"
    autocomplete="off">
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<!-- Button to increase selected date -->
<button mat-icon-button (click)="goNextDate()"><mat-icon>chevron_right</mat-icon></button>

<div>
  <div *ngIf="!loading" class="chart-wrapper">
    <div class="info-icon">
      <mat-icon>help</mat-icon>
      <div class="show-on-info-icon-hover">
        <h4>Tiderna är beräknade enligt: </h4>
        <ul>
          <li>Ankommande - när flighten beräknas landa på Arlanda</li>
          <li>Avresande - parkeringstidens början</li>
          <li>Garagebilar - totalt dvs. inklusive både ankommande och avresande</li>
        </ul>
      </div>
    </div>
    <app-travellers-chart [reports]="reports"></app-travellers-chart>
  </div>

  <div class="travellers-table" *ngIf="!loading">
    <table mat-table [dataSource]="reports">
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef> Tid </th>
        <td mat-cell *matCellDef="let report"> {{report.date | date: 'HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="arriving">
        <th mat-header-cell *matHeaderCellDef> Ankommande </th>
        <td mat-cell *matCellDef="let report" [ngClass]="{'zero': report.arrivalTravelers === 0}" title="Ankommande">
          {{report.arrivalTravelers}} </td>
      </ng-container>

      <ng-container matColumnDef="departing">
        <th mat-header-cell *matHeaderCellDef> Avresande </th>
        <td mat-cell *matCellDef="let report" [ngClass]="{'zero': report.departureTravelers === 0}" title="Avresande">
          {{report.departureTravelers}} </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef> Totalt antal resenärer </th>
        <td mat-cell *matCellDef="let report" [ngClass]="{'zero': report.totalTravelers === 0}"
          title="Totalt antal resenärer"> {{report.totalTravelers}} </td>
      </ng-container>

      <ng-container matColumnDef="garageIn">
        <th mat-header-cell *matHeaderCellDef> Garagebilar in </th>
        <td mat-cell *matCellDef="let report" [ngClass]="{'zero': report.departureGarageCars === 0}"
          title="Garagebilar in"> {{report.departureGarageCars}} </td>
      </ng-container>

      <ng-container matColumnDef="garageOut">
        <th mat-header-cell *matHeaderCellDef> Garagebilar ut </th>
        <td mat-cell *matCellDef="let report" [ngClass]="{'zero': report.arrivalGarageCars === 0}"
          title="Garagebilar ut"> {{report.arrivalGarageCars}} </td>
      </ng-container>

      <ng-container matColumnDef="garage">
        <th mat-header-cell *matHeaderCellDef> Totalt antal garagebilar </th>
        <td mat-cell *matCellDef="let report" [ngClass]="{'zero': report.totalGarageCars === 0}"
          title="Totalt antal garagebilar"> {{report.totalGarageCars}} </td>
      </ng-container>

      <tr mat-header-row
        *matHeaderRowDef="['time', 'arriving', 'departing', 'total', 'garageIn', 'garageOut', 'garage']"></tr>
      <tr mat-row class="mat-row"
        *matRowDef="let rowData; columns: ['time', 'arriving', 'departing', 'total', 'garageIn', 'garageOut', 'garage'];">
      </tr>
    </table>
  </div>
</div>

<mat-spinner *ngIf="loading" class="spinner"></mat-spinner>