<div class="payment-report-wrapper">
  <h2>Betalningsrapport</h2>

  <!-- Datepicker -->
  <div>
    <mat-form-field>
      <mat-label>Välj period</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Startdatum" [formControl]="startDate" (dateChange)="updateData()">
        <input matEndDate placeholder="Slutdatum" [formControl]="endDate" (dateChange)="updateData()">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>

  <!-- Chart -->
  <ng-container *ngIf="!loadingData && !setEmpty">
    <stat-barchart [chartData]="chartDataSet" [titleArray]="chartTitles" [colorArray]="chartColors"></stat-barchart>
  </ng-container>

  <!-- Table -->
  <div *ngIf="!setEmpty">
    <table mat-table [dataSource]="paymentReport">
      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef>Betalningsmetod</th>
        <td mat-cell *matCellDef="let report" [ngClass]="{'total': report.label === 'Total'}">{{report.label}}</td>
        <td mat-footer-cell *matFooterCellDef>Total </td>
      </ng-container>

      <ng-container matColumnDef="payments">
        <th mat-header-cell *matHeaderCellDef class="number-data">Betalningar</th>
        <td mat-cell *matCellDef="let report" [ngClass]="{'total': report.label === 'Total'}" class="number-data">{{report.payments | number: '' : 'sv'}} kr</td>
        <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('payments') | number: "1.0-0"}} kr</td>
      </ng-container>

      <ng-container matColumnDef="refunds">
        <th mat-header-cell *matHeaderCellDef class="number-data">Återbetalningar</th>
        <td mat-cell *matCellDef="let report" [ngClass]="{'total': report.label === 'Total'}" class="number-data">{{report.refunds | number: '' : 'sv'}} kr</td>
        <td mat-footer-cell *matFooterCellDef class="number-data">{{getTotalByType('refunds') | number: "1.0-0"}} kr</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['method', 'payments', 'refunds']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['method', 'payments', 'refunds']"></tr>
      <tr mat-footer-row *matFooterRowDef="['method', 'payments', 'refunds']"></tr>
    </table>
  </div>


  <!-- Empty set info -->
  <div *ngIf="setEmpty && !loadingData" class="empty-set">
    <h4>Det finns inga data för den valda perioden. Använd datumväljaren ovan för att välja andra datum.</h4>
  </div>

  <!-- Spinner -->
  <div *ngIf="loadingData">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>