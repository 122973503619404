import { Component, Input } from '@angular/core';
import { FlightDTO } from 'parking-sdk';
import { DateService } from 'src/app/core/services/date.service';

@Component({
  selector: 'app-flight-information',
  templateUrl: './flight-information.component.html',
  styleUrls: ['./flight-information.component.scss'],
})
export class FlightInformationComponent {
  @Input() flight?: FlightDTO;
  @Input() flightInput?: string;
  @Input() destination?: string;
  @Input() travelHomeFrom?: string;
  @Input() scheduledDate?: Date;
  @Input() estimatedDate?: Date;
  @Input() actualDate?: Date;

  constructor(private dateService: DateService) {}

  formatDate(date: string | Date | undefined): string {
    return date ? this.dateService.formatDate(date) : '';
  }

  formatTime(date: string | Date | undefined): string {
    return date ? this.dateService.formatTime(date) : '';
  }
}
