<mat-toolbar color="accent">
  <button [routerLink]="['/antal-p-platser']" routerLinkActive="active-link">Antal P-platser</button>

  <button [matMenuTriggerFor]="reportMenu" #reportMenuTrigger="matMenuTrigger"
    class="{{rlReport.isActive ? 'active-link' : reportMenuTrigger.menuOpen ? 'sub-admin-menu-open' : ''}}">
    Rapporter
  </button>

  <mat-menu #reportMenu="matMenu" class="sub-admin-menu">
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/betalningsrapport']">
      Betalningsrapport
    </button>
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/belaggningsrapport']">
      Beläggningsrapport
    </button>
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/forskottsbetalningar']">
      Förskottsbetalningar
    </button>
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/dagsrapport-artiklar']">
      Dagsrapport - artiklar
    </button>
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/dagsrapport-betalmetod']">
      Dagsrapport - betalmetod
    </button>
  </mat-menu>

  <button [routerLink]="['/anvandare']" routerLinkActive="active-link">Användare</button>

  <button [matMenuTriggerFor]="settingsMenu" #settingsMenuTrigger="matMenuTrigger"
    class="{{rlSettings.isActive ? 'active-link' : settingsMenuTrigger.menuOpen ? 'sub-admin-menu-open' : ''}}">
    Inställningar
  </button>

  <mat-menu #settingsMenu="matMenu" class="sub-admin-menu">
    <button [routerLink]="['/sms-installningar']" routerLinkActive #rlSettings="routerLinkActive">
      SMS-bekräftelse
    </button>
    <!-- <button routerLinkActive #rlSettings="routerLinkActive" >Inställning 2</button> -->
  </mat-menu>
  
  <!-- class="{{rlPartners.isActive ? 'active-link' : partnersMenuTrigger.menuOpen ? 'sub-admin-menu-open' : ''}}" -->
  <button [matMenuTriggerFor]="partnersMenu" #partnersMenuTrigger="matMenuTrigger"
    >
    Partnerportalen
  </button>

  <!-- [routerLink]="['/samarbetspartner/' + partner.partnerId]" -->
  <mat-menu #partnersMenu="matMenu" class="sub-admin-menu">
    <button *ngFor="let partner of partners"  (click)="setCurrentPartner(partner)" routerLinkActive #rlPartners="routerLinkActive">
      {{partner.name}}
    </button>
  </mat-menu>
</mat-toolbar>