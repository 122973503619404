import { PrepaidTicketCarDTO, PrepaidTicketDTO } from 'parking-sdk';

export class GetPrepaidTickets {
  static readonly type = '[Prepaid] Get prepaid tickets';
  constructor() {}
}

export class GetPrepaidTicket {
  static readonly type = '[Prepaid] Get prepaid ticket';
  constructor(public ticketId: number) {}
}

export class AddPrepaidTicket {
  static readonly type = '[Prepaid] Add new prepaid ticket';
  constructor(public ticket: PrepaidTicketDTO) {}
}

export class UpdatePrepaidTicket {
  static readonly type = '[Prepaid] Update existing prepaid ticket';
  constructor(public ticketId: number, public ticket: PrepaidTicketDTO) {}
}

export class DeletePrepaidTicket {
  static readonly type = '[Prepaid] Delete prepaid ticket';
  constructor(public ticketId: number) {}
}

export class PrepaidSearchListener {
  static readonly type = `[Prepaid] Listener for Search prepaid tickets`;
  constructor(public term: string) {}
}
