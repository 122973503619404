import { NgModule } from '@angular/core';
import { RelyitComponent } from './relyit.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfirmComponent } from './confirm/confirm.component';



@NgModule({
  declarations: [
    RelyitComponent,
    ConfirmComponent
  ],
  imports: [
    SharedModule
  ]
})
export class RelyitModule { }
