<span class="total-passengers" *ngIf="totalPassengers !== undefined">
  <mat-icon>people</mat-icon>
  <span>{{ totalPassengers }}</span>
</span>

<table *ngIf="bookings.length > 0" mat-table [dataSource]="bookings"
  class="{{ coloredSearchResult ? 'search-result-color' : null }}">
  <!-- Booking number Column -->
  <ng-container matColumnDef="bookingNumber">
    <th mat-header-cell *matHeaderCellDef class="booking-number-th">Bokningsnr.</th>
    <td data-test="booking.nr" mat-cell *matCellDef="let element">
      {{ element.qr }}
      <span *ngIf="!element.qr">-</span>
    </td>
  </ng-container>

  <!-- Registration number Column -->
  <ng-container matColumnDef="registrationNumber">
    <th mat-header-cell *matHeaderCellDef class="registration-number-th">Reg.nr</th>
    <td data-test="reg.nr" mat-cell *matCellDef="let element">
      {{ element.registrationNumber }}
    </td>
  </ng-container>

  <!-- Location Column -->
  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef>Plats</th>
    <td mat-cell *matCellDef="let element">
      {{ element.resource.label || "okänt" }}
    </td>
  </ng-container>

  <!-- Flight Column -->
  <ng-container matColumnDef="flightNumber">
    <th mat-header-cell *matHeaderCellDef>Flightnr.</th>
    <td mat-cell *matCellDef="let element" matTooltip="{{
        topic === 'departure' && element.departureFlight
          ? 'Flyg till ' +
            element.departureFlight.arrivalAirport +
            ', avgår: ' +
            formatTime(element.departureFlight.scheduledDepartureDate)
          : undefined
      }}">
      {{
      topic === "arrival"
      ? element.flightNumber || 'okänt'
      : topic === "departure"
      ? element.departureFlight?.flightNumber || 'okänt'
      : topic === "garage"
      ? element.arrivalFlightNumber || 'okänt'
      : element.direction == "arrival"
      ? element.arrivalFlight?.flightNumber || "okänt"
      : element.departureFlight?.flightNumber || "okänt"
      }}
    </td>
  </ng-container>

  <!-- Hand bagage Column -->
  <ng-container matColumnDef="handLuggage">
    <th mat-header-cell *matHeaderCellDef>Incheckat bagage</th>
    <td mat-cell *matCellDef="let element">
      {{ element.handLuggageOnly ? "Nej" : "Ja" }}
    </td>
  </ng-container>

  <!-- Payment Column -->
  <ng-container matColumnDef="paymentStatus">
    <th mat-header-cell *matHeaderCellDef>Betalning</th>
    <td mat-cell *matCellDef="let element" matTooltip="{{
        element.amountToPay > 0
          ? 'KRAV föreligger, se bokning för detaljer'
          : undefined
      }}">
      {{ element.amountToPay > 0 ? "KRAV" : element.amountToPay <= 0 ? "Betald" : "okänt" }} </td>
  </ng-container>

  <!-- Child Safety Seat Column -->
  <ng-container matColumnDef="childSafetySeat">
    <th mat-header-cell *matHeaderCellDef class="child-safety-seat">Bilbarnstol</th>
    <td mat-cell *matCellDef="let element">
      {{ element.childSafetySeat ? "Ja" : "Nej" }}
    </td>
  </ng-container>

  <!-- Comments Column -->
  <ng-container matColumnDef="comments">
    <th mat-header-cell *matHeaderCellDef>Kommentar</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.comment" matTooltip="{{ element.comment }}">chat_bubble</mat-icon>
      <span *ngIf="!element.comment">-</span>
    </td>
  </ng-container>

  <!-- Car Wash Column -->
  <ng-container matColumnDef="carWash">
    <th mat-header-cell *matHeaderCellDef>Tilläggstjänster</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.carWash">local_car_wash</mat-icon>
      <mat-icon *ngIf="element.garageService">bolt</mat-icon>
      <span *ngIf="!element.carWash && !element.garageService">-</span>
    </td>
  </ng-container>

  <!-- Travel Home From Column -->
  <ng-container matColumnDef="travelHomeFrom">
    <th mat-header-cell *matHeaderCellDef>Rest hem från</th>
    <td mat-cell *matCellDef="let element">
      <span>{{element.travelHomeFrom}}</span>
    </td>
  </ng-container>

  <!-- Arrival Parking date Column -->
  <ng-container matColumnDef="parkTime" class="park-datetime">
    <th mat-header-cell *matHeaderCellDef class="park-datetime">Sluttid parkering</th>
    <td mat-cell *matCellDef="let element" class="park-datetime"
      [matTooltip]="formatDate(element.arrivalDate) + ' ' + formatTime(element.arrivalDate)">
      {{
      element.arrivalDate
      ? formatMonthAndDay(element.arrivalDate) +
      " " +
      formatTime(element.arrivalDate)
      : ""
      }}
    </td>
  </ng-container>

  <!-- Departure date Column -->
  <ng-container matColumnDef="departureDate" class="park-datetime">
    <th mat-header-cell *matHeaderCellDef
      (click)="topic === 'garage' || topic === 'all' ? sortColumn($event) : undefined" data-column="departureDate"
      class="{{topic === 'garage' || topic === 'all' ? 'sort-column' : ''}} park-datetime">
      {{ topic === "departure" ? "Parkering startar" : "Avresa" }}

      <mat-icon *ngIf="topic === 'garage' || topic === 'all'"
        class="sort-column-arrow {{prevTargetColumn === 'departureDate' ? 'show-sort-column-arrow': ''}}"
        data-column="departureDate">
        {{sortOrder !== 'desc' ? 'north' : prevTargetColumn === 'departureDate' ? 'south' : 'north'}}
      </mat-icon>
    </th>
    
    <td mat-cell *matCellDef="let element"
      [matTooltip]="formatDate(element.departureDate) + ' ' + formatTime(element.departureDate)"
      class="park-datetime">
      {{
      element.departureDate ?
      formatMonthAndDay(element.departureDate) +
      " " +
      formatTime(element.departureDate) : 'okänt'
      }}
    </td>
  </ng-container>

  <!-- Arrival date Column -->
  <ng-container matColumnDef="arrivalDate">
    <th mat-header-cell *matHeaderCellDef
      (click)="topic === 'garage' || topic === 'all' ? sortColumn($event) : undefined" data-column="arrivalDate"
      class="{{topic === 'garage' || topic === 'all' ? 'sort-column' : ''}}">
      Ankomst

      <mat-icon *ngIf="topic === 'garage' || topic === 'all'"
        class="sort-column-arrow {{prevTargetColumn === 'arrivalDate' ? 'show-sort-column-arrow': ''}}"
        data-column="arrivalDate">
        {{sortOrder !== 'desc' ? 'north' : prevTargetColumn === 'arrivalDate' ? 'south' : 'north'}}
      </mat-icon>
    </th>
    <td mat-cell *matCellDef="let element">
      {{
      element.arrivalDate
      ? formatDate(element.arrivalDate) +
      " " +
      formatTime(element.arrivalDate)
      : ""
      }}
    </td>
  </ng-container>

  <!-- Quantity persons Column -->
  <ng-container matColumnDef="qtyPersons">
    <th mat-header-cell *matHeaderCellDef class="qty-persons">Resenärer</th>
    <td mat-cell *matCellDef="let element">{{ element.qtyPersons }}</td>
  </ng-container>

  <!-- Picked up / Dropped off passengers Column -->
  <ng-container matColumnDef="drivenPassengers">
    <th mat-header-cell *matHeaderCellDef class="pick-up-header-cell">
      {{topic == 'arrival' ? 'Hämtade' : 'Lämnade'}}
    </th>
    <td mat-cell *matCellDef="let element" (click)="preventRowClick($event)" class="pick-up-cell">
      <app-check-off-passengers [booking]="element"
        [direction]="topic === 'arrival' ? 'arrival': 'departure'"></app-check-off-passengers>
    </td>
  </ng-container>

  <!-- Quantity persons Column -->
  <ng-container matColumnDef="direction" class="direction" *ngIf="!topic">
    <th mat-header-cell *matHeaderCellDef>Riktning</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon [ngClass]="{ arrival: element.direction === 'arrival' }">
        {{ element.direction == "arrival" ? "flight_land" : "flight_takeoff" }}
      </mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row (click)="showBooking(row); close()" *matRowDef="let row; columns: displayedColumns"></tr>
</table>