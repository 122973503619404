<h3 mat-dialog-title>Betalning genomförd</h3>

<div mat-dialog-content>
  <p class="message">En kontant betalning på 0 kr har registrerats till kassasystemet.</p>

  <!-- Close/confirm -->
  <div mat-dialog-actions class="dialogActions">

    <button 
    mat-button 
    (click)="closeDialog(true)">
      Stäng
    </button>

    <button
    mat-button
    class="button"
    (click)="closeAndPrintReceipt()">
      Skriv ut kvitto
    </button>
  </div>
</div>