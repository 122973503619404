<div class="container" mat-dialog-content>
  <h3>Skapa prislista</h3>

  <form *ngIf="pricelistForm" [formGroup]="pricelistForm" (ngSubmit)="onCreateClick()" spellcheck="false"
    autocomplete="off">
    <mat-form-field>
      <mat-label>Namn</mat-label>
      <input matInput formControlName="name" name="name">
    </mat-form-field>

    <div class="form-fieldset">
      <mat-form-field>
        <mat-label>Välj typ</mat-label>
        <mat-select formControlName="selectedType" name="selectedType">
          <mat-option value="resource">Parkeringspriser</mat-option>
          <mat-option value="feature">Tilläggstjänster</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="pricelistForm.controls['selectedType'].value === 'resource'">
        <mat-label>Parkering</mat-label>
        <mat-select formControlName="resource" name="resource">
          <mat-option *ngFor="let option of resourceOptions" [value]="option">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-fieldset">
      <mat-form-field>
        <mat-label>Giltig från</mat-label>
        <input matInput [matDatepicker]="validFromPicker" placeholder="Giltig från" formControlName="validFrom"
          name="validFrom">
        <mat-datepicker-toggle matSuffix [for]="validFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #validFromPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Giltig till</mat-label>
        <input matInput [matDatepicker]="validToPicker" placeholder="Giltig till" formControlName="validTo"
          name="validTo">
        <mat-datepicker-toggle matSuffix [for]="validToPicker"></mat-datepicker-toggle>
        <mat-datepicker #validToPicker></mat-datepicker>
        <mat-hint>tomt = tillsvidare</mat-hint>
      </mat-form-field>
    </div>

    <div class="buttons">
      <button type="button" mat-button (click)="onCancelClick()">Avbryt</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="pricelistForm.invalid">Skapa</button>
    </div>
  </form>
</div>