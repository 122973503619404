<h3 mat-dialog-title>Återköp banköverföring</h3>

<div mat-dialog-content>
  <p>Max summa för återbetalning: {{data.maxRefund}} kr</p>

  <form>
    <!-- Refund sum -->
    <div class="input">
      <mat-form-field>
        <mat-label>Summa att återbetala</mat-label>
        <input matInput type="number" [formControl]="refundAmt">
        <mat-error>Ogiltigt belopp</mat-error>
      </mat-form-field>
    </div>

    <!-- Refund comment -->
    <div class="input">
      <mat-form-field>
        <mat-label>Anledning till återköp</mat-label>
        <input matInput [formControl]="refundCmt">
        <mat-error>Anledning krävs</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div mat-dialog-actions class="dialog-actions">
    <button mat-button mat-dialog-close>Avbryt</button>
    <button mat-button (click)="refund()" [disabled]="!refundAmt.valid || !refundCmt.valid">Slutför</button>
  </div>
</div>