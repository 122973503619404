import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { DefaultService, ResourceDayDTO } from 'parking-sdk';
import { LineChartDataset } from 'rely-statistics-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-occupancy-report',
  templateUrl: './occupancy-report.component.html',
  styleUrls: ['./occupancy-report.component.scss'],
})
export class OccupancyReportComponent implements OnInit {
  startDate = new FormControl(dayjs().hour(0).minute(0).toDate());
  endDate = new FormControl(dayjs().add(1, 'week').hour(0).minute(0).toDate());
  resourceType: 'garage' | 'outside' = 'outside';

  chartTitles: string[] = [];
  chartData: LineChartDataset[] = [];
  loading = true;
  tableData: ResourceDayDTO[] = [];

  private onDestroy$ = new Subject<void>();

  constructor(
    private defaultService: DefaultService,
    private exportService: ExportService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  changeResourceType(value: 'garage' | 'outside') {
    this.resourceType = value;
    this.getData();
  }

  getData() {
    if (this.startDate.value && this.endDate.value) {
      this.loading = true;
      this.chartData = [];
      this.chartTitles = [];

      let type = 1;
      if (this.resourceType === 'outside') {
        type = 3;
      }

      this.defaultService
        .getResourceStatusAdmin(
          dayjs(this.startDate.value!).second(0).millisecond(0).toDate(),
          dayjs(this.endDate.value!)
            .second(0)
            .millisecond(0)
            .add(1, 'day')
            .toDate()
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          this.tableData = data;
          let arrivalData: number[] = [],
            departureData: number[] = [],
            totalData: number[] = [];

          data.forEach((val) => {
            this.chartTitles.push(formatDate(val.date!, 'd MMM', 'sv-SE'));
            arrivalData.push(val.allocations![type].arrivalQty!);
            departureData.push(val.allocations![type].departureQty!);
            totalData.push(val.allocations![type].occupiedQty!);
          });

          this.chartData.push({
            data: arrivalData,
            name: 'Ankommande',
            type: 'line',
          });

          this.chartData.push({
            data: departureData,
            name: 'Avgående',
            type: 'line',
          });

          this.chartData.push({
            data: totalData,
            name: 'Fyllda platser',
            type: 'line',
          });

          this.loading = false;
        });
    }
  }

  downloadReportBookings() {
    if (this.startDate.value && this.endDate.value)
      this.exportService.getReportBookings(
        dayjs(this.startDate.value).second(0).millisecond(0).toDate(),
        dayjs(this.endDate.value).second(0).millisecond(0).add(1, 'day').toDate()
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
