<div mat-dialog-content>
  <h3>{{editedAvailability.availabilityId ? 'Redigera tillgängliga P-platser' : 'Lägg till tillgängliga P-platser'}}</h3>
  <form [formGroup]="editForm" class="edit-form" autocomplete="off">
    <mat-form-field>
      <mat-label>Antal platser</mat-label>
      <input matInput autocomplete="off" name="qty" formControlName="qty" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Välj typ av parkering</mat-label>
      <mat-select formControlName="resource" name="resource" placeholder="Välj typ av parkering" (selectionChange)="onSelectChange()" required>
        <mat-option *ngFor="let resource of availableResources"
          [value]="resource.resourceId">{{resource.label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="wideInput">
      <mat-label>Välj period</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Startdatum" name="validFrom" formControlName="validFrom" required >
        <input matEndDate placeholder="Slutdatum" name="validTo" formControlName="validTo" >
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
  <div class="invalid-message" *ngIf="invalidFormMessage">
    <mat-icon>error</mat-icon> <span>{{invalidFormMessage}}</span> <mat-icon>error</mat-icon>
  </div>
</div>
<div mat-dialog-actions id="buttons">
  <button mat-button (click)="onRemoveClick()" color="warn" class="{{editedAvailability.availabilityId ? '' : 'hidden'}}">Ta bort</button>
  <div class="flex-end-buttons-wrapper">
    <button mat-flat-button (click)="onCancelClick()">Avbryt</button>
    <button mat-raised-button (click)="onDoneClick()" color="primary">Spara</button>
  </div>
</div>