<h2 class="no-margin-bottom">Alla bokningar</h2>

<div class="filter-wrapper">
    <div>
        <div class="searchbox-wrapper">
            <input
                type="search"
                placeholder="Sök på reg.nr eller bokningsnr."
                [formControl]="searchInput"
                (input)="search()"
                data-test="search-all-bookings"
                spellcheck="false" 
                autocomplete="off"
            />
            <form class="filters" [formGroup]="filterCheckboxes">
                <mat-radio-group formControlName="resource" (change)="filter()">
                    <mat-label><h5 class="filters-group-label">Plats:</h5></mat-label>
                    <mat-radio-button color="primary" value="all">Alla</mat-radio-button>
                    <mat-radio-button color="primary" value="garage">Garage</mat-radio-button>
                    <mat-radio-button color="primary" value="outdoor">Utomhus</mat-radio-button>
                </mat-radio-group>
                <div>
                    <h5 class="filters-group-label">Filter:</h5>
                    <mat-checkbox color="primary" disabled="true">Årskort</mat-checkbox>
                    <mat-checkbox color="primary" disabled="true">Laddbilar</mat-checkbox>
                    <mat-checkbox color="primary" disabled="true">Tvättbilar</mat-checkbox>
                    <mat-checkbox color="primary" disabled="true">Verkstadsbilar</mat-checkbox>
                    <mat-checkbox color="primary" disabled="true">Bilbarnstol</mat-checkbox>
                </div>
            </form>
        </div>
    </div> 

    <!-- Date filter -->
    <div class="date-filter">
        <div>
            <mat-form-field>
                <mat-label>Period</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate name="from" [formControl]="startDate" placeholder="Startdatum" (dateInput)="dispatchGetAllBookings()"/>
                    <input matEndDate name="to" [formControl]="endDate" placeholder="Slutdatum" (dateInput)="dispatchGetAllBookings()"/>
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div>
            <mat-radio-group [formControl]="filterDateOn">
                <mat-radio-button [value]="'arrival'" (click)="changeDateFilter('arrival')" color="primary">Ankomst</mat-radio-button>
                <mat-radio-button [value]="'departure'" (click)="changeDateFilter('departure')" color="primary">Avresa</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>

<mat-paginator 
    [length]="totalBookings"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true"
    aria-label="Välj sida"
    (page)="paginator($event)">
</mat-paginator>

<div class="spinner-wrapper" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>

<div class="empty-content" *ngIf="bookings.length <= 0">
    <p>Kan inte hitta någon bokning<span *ngIf="searchInput.value"> som matchar "{{searchInput.value}}"</span>.</p>
    <p>Kontrollera att du skrivit rätt registrerings- eller bokningsnummer och anpassa eventuella filter.</p>
</div>

<app-bookings-table [bookings]="bookings" topic="all" [sortOrder]="sortOrder" [prevTargetColumn]="sortByColumn" (sortColumnClick)="sortColumns($event)"></app-bookings-table>

<mat-paginator 
    [length]="totalBookings"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="true"
    aria-label="Välj sida"
    (page)="paginator($event)">
</mat-paginator>