import { Component, Input } from '@angular/core';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { SimpleBookingDTO, SimpleHourBookingDTO } from 'parking-sdk';
import { Subject, map, debounceTime, takeUntil, filter } from 'rxjs';
import { QtyPersonCheckOffListener,  UpdateQtyPersonsCheckedOff } from 'src/app/core/states/bookings/booking.actions';

@Component({
  selector: 'app-check-off-passengers',
  templateUrl: './check-off-passengers.component.html',
  styleUrls: ['./check-off-passengers.component.scss']
})
export class CheckOffPassengersComponent {
  @Input() booking?: SimpleBookingDTO | SimpleHourBookingDTO;
  @Input() direction: 'departure' | 'arrival' = 'departure';
  total: number = 0;
  checkedOff: number = 0;
  editCheckOff: boolean = false;

  private debounceTimerMs: number = 0;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store, 
    actions$: Actions
    ) {
      actions$
        .pipe(
          ofActionDispatched(QtyPersonCheckOffListener),
          map((action: QtyPersonCheckOffListener) => action),
          filter((action: QtyPersonCheckOffListener) => this.booking?.bookingId === action.bookingId),
          debounceTime(this.debounceTimerMs),
          takeUntil(this.destroy$)
        )
        .subscribe((action) => {
          this.store
            .dispatch(new UpdateQtyPersonsCheckedOff(action.direction ,action.bookingId, action.qtyPersonsCheckedOff));
        });
  }

  ngOnInit(): void{
    this.total = this.booking?.qtyPersons ? this.booking?.qtyPersons : 0 ;
    this.checkedOff = this.direction === 'arrival' ? this.booking?.qtyPersonsPickedUp || 0 : this.booking?.qtyPersonsDroppedOff || 0 ;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleEdit(e:any){
      this.editCheckOff = !this.editCheckOff   
  }

  changeNumOfCheckedOff(op: '+' | '-'){   
    if(op === '+' && this.checkedOff < this.total) this.checkedOff++;
    if(op === '-' && this.checkedOff > 0) this.checkedOff--;
    if(this.booking?.bookingId) this.store.dispatch(new QtyPersonCheckOffListener(this.direction, this.booking.bookingId, this.checkedOff));
  }

  checkOffAll(checked:boolean){
    if(this.booking?.bookingId && checked) this.store.dispatch(new UpdateQtyPersonsCheckedOff(this.direction, this.booking.bookingId, this.total));
    if(this.booking?.bookingId && !checked) this.store.dispatch(new UpdateQtyPersonsCheckedOff(this.direction, this.booking.bookingId, 0));
  }
}
