import { NgModule } from '@angular/core';
import { PricelistsComponent } from './pricelists.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    PricelistsComponent,
  ],
  imports: [SharedModule],
  exports: [PricelistsComponent],
})
export class PricelistsModule {}
