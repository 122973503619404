import { Component, OnInit } from '@angular/core'
import { RoleDTO, UserDTO } from 'parking-sdk'
import { Observable, Subject, map, takeUntil } from 'rxjs'
import { EditUserComponent } from 'src/app/components/edit-user/edit-user.component'
import { CreateUserComponent } from 'src/app/components/create-user/create-user.component'
import { MatDialog } from '@angular/material/dialog'
import { Store } from '@ngxs/store'
import { UsersState } from 'src/app/core/states/users/users.state'
import { AddUser, GetUsers } from 'src/app/core/states/users/user.actions'

@Component({
  selector: 'app-user-administration',
  templateUrl: './user-administration.component.html',
  styleUrls: ['./user-administration.component.scss'],
})
export class UserAdministrationComponent implements OnInit {
  users?: UserDTO[]

  private onDestroy$ = new Subject<void>();

  constructor(private store: Store, private dialog: MatDialog) {
   this.store.select(UsersState.currentUsers).pipe(takeUntil(this.onDestroy$)).subscribe(v => {
    this.users = v
   })
  }

  ngOnInit() {
    this.store.dispatch(GetUsers)
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase()
    if (filterValue === '') {
      this.store.select(UsersState.currentUsers).pipe(takeUntil(this.onDestroy$)).subscribe(v =>  this.users = v)
    } else {
      this.store.select(UsersState.currentUsers).pipe(
        map(users => users.filter(user =>
          user.id?.toString().includes(filterValue) ||
          user.username?.toLowerCase().includes(filterValue) ||
          user.roles?.some(role => role.label && role.label.toLowerCase().includes(filterValue))
        ))
      ).pipe(takeUntil(this.onDestroy$)).subscribe(v => this.users = v)
    }
  }

  //TODO: Error handling
  createUser(): void {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '600px',
      data: {},
    })

    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (result) {
        // Use the values from the dialog result to create a new user
        const newUser: UserDTO = {
          username: result.username,
          roles: result.roles as RoleDTO[],
          newPassword: result.newPassword
        }

        this.store.dispatch(new AddUser(newUser)) // Dispatch the new action with the password
      }
    })
  }

  //TODO: Error handling
  openEditDialog(user: UserDTO): void {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '600px',
      data: { user },
    })

    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.store.dispatch(GetUsers)
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
