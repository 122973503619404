<div class="started-payments" *ngIf="startedPayments?.length">
    <h3><mat-icon>warning</mat-icon><span>Viktigt!</span><mat-icon>warning</mat-icon></h3>
    <p>Det finns startade betalningar på denna order som inte har fullföljts. <strong>Alla dessa betalningar behöver
        återupptas</strong> för att kontrollera om pengar har dragits eller ej från kunden samt synka eventuell
      betalning till kassasystemet. </p>

    <ul>
      <li *ngFor="let payment of startedPayments">
        <div class="payment-info">
          <span>Betalningsmetod: {{payment.paymentMethod?.label ||
            'okänt'}}
          </span>
          <span>Betalning startad: {{formatDate(payment.createdDate) || 'okänt'}}
            {{formatTime(payment.createdDate) || 'okänt'}}
          </span>
          <span>Betalnings-ID: {{payment.paymentId}}</span>
          <span>Belopp: {{payment.amount}} kr</span>
        </div>
        <button class="button slim success" (click)="continue(payment.externalOrderId)">
          Återuppta betalning
        </button>
      </li>
    </ul>
  </div>