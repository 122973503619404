<span class="edit-pick-up">
    <div class="pick-up-controls">
        <span><button class="dec-btn" (click)="changeNumOfCheckedOff('-')" matTooltip="Minska antal"
                [disabled]="checkedOff <= 0" *ngIf="editCheckOff"><mat-icon>remove</mat-icon></button>
        </span>
        <span class="{{editCheckOff ? '' : 'pick-up-numbers'}}" (click)="toggleEdit($event)"
            matTooltip="Justera antal hämtade">{{checkedOff}} / {{total}}
        </span>
        <span><button class="inc-btn" (click)="changeNumOfCheckedOff('+')" matTooltip="Öka antal"
                [disabled]="checkedOff >= total" *ngIf="editCheckOff"><mat-icon>add</mat-icon></button>
        </span>
        <mat-checkbox class="pick-up-checkbox" [checked]="checkedOff === total"
            [indeterminate]="checkedOff > 0 && checkedOff < total" (change)="checkOffAll($event.checked)">
        </mat-checkbox>
        <div class="edit-pick-up-backdrop" *ngIf="editCheckOff" (click)="toggleEdit($event)"></div>
    </div>
</span>