import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { FeaturePriceDTO, PriceDTO, PriceListDTO, RoleDTO } from 'parking-sdk';
import { Observable, Subject, firstValueFrom, take, takeUntil } from 'rxjs';
import {
  DeletePricelist,
  GetPricelistById,
  GetPricelists,
  UpdatePriceList,
} from 'src/app/core/states/pricelist/pricelist.actions';
import { PricelistState } from 'src/app/core/states/pricelist/pricelist.state';
import { MatDialog } from '@angular/material/dialog';
import { CreatePricelistFeatureComponent } from '../../components/create-pricelist-feature/create-pricelist-feature.component';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { UsersState } from 'src/app/core/states/users/users.state';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.scss'],
})
export class PricelistComponent implements OnInit, OnDestroy {
  selectedPriceList$!: Observable<PriceListDTO | null>;
  featuresDatasource: MatTableDataSource<FeaturePriceDTO> = new MatTableDataSource<FeaturePriceDTO>();
  resourcesDatasource: MatTableDataSource<PriceDTO> = new MatTableDataSource<PriceDTO>();
  private onDestroy$ = new Subject<void>();
  updatedPriceList: PriceListDTO = {};
  editMode = false;

  isAdmin: boolean = false;

  displayedResourceColumns: string[] = [
    'days',
    'priceResource',
  ];

  displayedEditResourceColumns: string[] = [
    'days',
    'priceResource'
  ];

  displayedFeatureColumns: string[] = [
    'featureName',
    'price',
    'priceSUV'
  ];

  displayedEditFeatureColumns: string[] = [
    'featureName',
    'price',
    'priceSUV',
    'delete',
  ];

  listEditForm: FormGroup = new FormGroup([]);

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit() {
    this.store.selectOnce(UsersState.loggedInUser).pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.isAdmin = user?.roles ? user.roles.some((role: RoleDTO) => role.name === 'ROLE_ADMIN') : false;
    });

    const params = await firstValueFrom(this.route.paramMap.pipe(take(1)));
    const priceListIdString = params.get('id');
    if (!priceListIdString) {
      console.error('PriceListId is undefined');
      return;
    }

    const priceListId = +priceListIdString;
    this.store.dispatch(new GetPricelistById(priceListId));

    this.selectedPriceList$ = this.store
      .select(PricelistState.selectedPriceLists)
      .pipe(takeUntil(this.onDestroy$));
    
    this.selectedPriceList$.subscribe((data) => {
      this.featuresDatasource.data = data?.featurePrices!;
      this.resourcesDatasource.data = data?.prices!;
    });

    
  }

  editPriceList() {
    this.selectedPriceList$.subscribe((list) => {
      this.updatedPriceList = {...list};
      this.listEditForm = this.formBuilder.group({
        listName: new FormControl<string | undefined>(
          this.updatedPriceList.name || undefined
        ),
        validFrom: new FormControl<Date | undefined>(
          this.updatedPriceList.validFrom || undefined
        ),
        validTo: new FormControl<Date | undefined>(
          this.updatedPriceList.validTo
        )
      });
      this.featuresDatasource.data = this.updatedPriceList.featurePrices!;
    });
    this.editMode = true;
    this.updatedPriceList.featurePrices?.forEach((price) => {
      this.listEditForm?.addControl('featurePrice' + price.featurePriceId, new FormControl<number | undefined>(price.price, Validators.required));
      this.listEditForm?.addControl('featurePriceB' + price.featurePriceId, new FormControl<number | undefined>(price.priceB));
    });
    this.updatedPriceList.prices?.forEach((price) => {
      this.listEditForm?.addControl('price' + price.priceId, new FormControl<number | undefined>(price.price, Validators.required));
    });
  }

  saveChanges() {
    if(this.listEditForm.valid) {
      this.updatedPriceList.featurePrices?.forEach((price) => {
        price.price = this.listEditForm?.controls['featurePrice' + price.featurePriceId].value;
        price.priceB = this.listEditForm?.controls['featurePriceB' + price.featurePriceId].value;
      });

      this.updatedPriceList.prices?.forEach((price) => {
        price.price = this.listEditForm?.controls['price' + price.priceId].value;
      });

      this.updatedPriceList = {
        ...this.updatedPriceList,
        validFrom: this.listEditForm?.controls['validFrom'].value,
        validTo: this.listEditForm?.controls['validTo'].value,
        name: this.listEditForm?.controls['listName'].value
      }
      this.store.dispatch(new UpdatePriceList(this.updatedPriceList));
      this.editMode = false;
    }
    
  }

  cancelEdit() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Är du säker på att du vill stänga redigeringen?',
        message: [`Ev. ändringar sparas inte`],
        yesBtnText: 'Stäng redigering',
        noBtnText: 'Stanna kvar'
      }
    });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.ngOnInit();
          this.editMode = false;
        }
      })
  }

  navigateBack() {
    if (!this.editMode) {
      this.router.navigate(['/prislistor']);
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Är du säker på att du vill stänga redigeringen?',
          message: [`Ev. ändringar sparas inte`],
          yesBtnText: 'Stäng redigering',
          noBtnText: 'Stanna kvar'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.router.navigate(['/prislista/' + this.updatedPriceList.priceListId]);
          this.ngOnInit();
          this.editMode = false;
          this.updatedPriceList = {};
        }
      })
    }
  }

  addMoreFeature() {
    const dialogRef = this.dialog.open(CreatePricelistFeatureComponent, {
      width: '600px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        const newFeature = {
          price: undefined,
          priceB: undefined,
          feature: result.feature
        };

        this.updatedPriceList.featurePrices?.push(newFeature);
        this.listEditForm?.addControl('featurePrice' + result.feature.featurePriceId, new FormControl<number | undefined>(newFeature.price, Validators.required));
        this.listEditForm?.addControl('featurePriceB' + newFeature.feature.featurePriceId, new FormControl<number | undefined>(newFeature.priceB));
        this.featuresDatasource.data = this.updatedPriceList.featurePrices!;
      }
    });
  }

  removeFeature(featureId: number, index: number) {
    const dialogRef =this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: [`Tjänsten ${this.updatedPriceList.featurePrices ? this.updatedPriceList.featurePrices[index].feature?.name : ''}?`]
      }
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        this.updatedPriceList.featurePrices?.splice(index, 1);
        this.listEditForm?.removeControl('featurePrice' + featureId);
        this.listEditForm?.removeControl('featurePriceB' + featureId);
        this.featuresDatasource.data = this.updatedPriceList.featurePrices!;
      }
    });
  }

  async deletePriceList() {
    const priceList = await firstValueFrom(
      this.selectedPriceList$.pipe(take(1))
    );

    if (priceList && priceList.priceListId !== undefined) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title:`Är du säker på att du vill ta bort "${priceList.name}"?`,
          message: [`Det kommer inte gå att återskapa listan`]
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (priceList && priceList.priceListId !== undefined) {
            const priceListToDelete = priceList.priceListId;
            this.store.dispatch(new DeletePricelist(priceListToDelete));
            this.store.dispatch(new GetPricelists()).subscribe(() => {
              this.router.navigate(['/prislistor']);
            });
          }
        }
      });
    } else {
      console.error('PriceListId is undefined');
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
