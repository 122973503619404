import { NgModule } from '@angular/core';
import { PricelistComponent } from './pricelist.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    PricelistComponent,
  ],
  imports: [SharedModule],
  exports: [PricelistComponent],
})
export class PricelistModule {}
