<mat-accordion [multi]="true" [hideToggle]="printGroups">
  <mat-expansion-panel *ngFor="let group of groupedBookings"
    class="{{isGroupCallCustomers(group) || isNoShowsCustomers(group) ? 'panel-margin-bottom':''}}  {{isGroupPickedUpCustomers(group) || isGroupDroppedOffCustomers(group) ? 'hide-on-print panel-margin-bottom':''}}"
    (opened)="handleExpansionPanelState(group, true)"
    (closed)="handleExpansionPanelState(group, false)"
    [expanded]="printGroups ? true : isExpansionPanelOpen(group)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="group-booking-info flight-number"
          *ngIf="isGroupArrival(group) && !isGroupCallCustomers(group) && !isGroupPickedUpCustomers(group)"
          matTooltip="{{checkPlaneHasLanded(group) ? 'Planet har landat' : null}}">
          <span *ngIf="checkPlaneHasLanded(group) && group.terminal"
            class="plane-has-landed fw-600">{{group.terminal}}
          </span>

          <mat-icon *ngIf="!checkPlaneHasLanded(group) || checkPlaneHasLanded(group) && !group.terminal"
            class="{{checkPlaneHasLanded(group) ? 'plane-has-landed' : 'rotate-icon'}}">{{checkPlaneHasLanded(group) ?
            'flight_land' : 'airplanemode_active'}}
          </mat-icon>

          {{ group.flightNumber }}
          <span class="time">
            {{ formatTime(group.arrivalDate) }}
          </span>
        </span>

        <span class="group-booking-info flight-number" *ngIf="isGroupArrival(group) && isGroupCallCustomers(group) || isGroupDeparture(group) && isNoShowsCustomers(group)">
          <mat-icon class="call-customer">phone</mat-icon>
          Ringkunder
        </span>

        <span class="group-booking-info {{isGroupPickedUpCustomers(group) ? 'flight-number' : 'time'}}"
          *ngIf="isGroupDroppedOffCustomers(group) || isGroupPickedUpCustomers(group)">
          <mat-icon class="call-customer">checklist_rtl</mat-icon>
          {{isGroupPickedUpCustomers(group) ? 'Hämtade' : 'Lämnade'}}
        </span>

        <span class="group-booking-info time" *ngIf="!isGroupArrival(group) && !isGroupDroppedOffCustomers(group) && !isNoShowsCustomers(group)">
          <mat-icon> directions_car</mat-icon>
          {{ formatTime(group.departureDate) }}
        </span>
        
        <span class="group-booking-info">
          <mat-icon>people</mat-icon>{{displayPassengerStatus(group)}}
        </span>

        <span *ngIf="isGroupArrival(group) && !isGroupCallCustomers(group) && !isGroupPickedUpCustomers(group)"
          class="group-booking-info travel-home-from"
          matTooltip="{{group.departureAirport!.length > 33 ? group.departureAirport : undefined}}"><!-- Only show tooltip when airport name is longer than 33 char ~350px wide -->
          {{group.departureAirport}}
        </span>

        <!-- Status icons -->
        <div class="info-icons">
          <span class="booking-info-icon" *ngIf="!isGroupPickedUpCustomers(group)">
            <mat-icon class="{{checkIfSomeInGroupWantCarSeat(group) ? 'booking-icon-active' : null}}"
              matTooltip="{{checkIfSomeInGroupWantCarSeat(group) ? 'Minst en bokning önskar bilbarnstol' : 'Inget behov av bilbarnstol'}}">
              child_care
            </mat-icon>
          </span>

          <span class="booking-info-icon">
            <mat-icon class="{{checkIfSomeInGroupHasDemand(group) ? 'booking-icon-active' : null}}"
              matTooltip="{{checkIfSomeInGroupHasDemand(group) ? 'Minst en bokning med KRAV' : 'Inga bokningar med KRAV'}}">credit_card</mat-icon>
          </span>

          <span class="booking-info-icon">
            <mat-icon class="{{checkIfSomeInGroupInGarage(group) ? 'booking-icon-active garage' : 'garage'}}"
              matTooltip="{{checkIfSomeInGroupInGarage(group) ? 'Minst en bil i garage' : 'Ingen bil i garage'}}">garage</mat-icon>
          </span>

          <span class="booking-info-icon">
            <mat-icon
              class="{{checkIfSomeInGroupHasCarWash(group) || checkIfSomeInGroupHasCharge(group) || checkIfSomeInGroupHasTireRepair(group) ? 'booking-icon-active' : null}}"
              matTooltip="{{checkIfSomeInGroupHasCarWash(group) || checkIfSomeInGroupHasCharge(group) || checkIfSomeInGroupHasTireRepair(group) ? 'Minst en bil med bokad tvätt, laddning och/eller däckverkstad' : 'Ingen bil med bokad tvätt, laddning eller däckverkstad'}}">local_car_wash</mat-icon>
          </span>

          <span class="booking-info-icon" *ngIf="isGroupArrival(group) && !isGroupPickedUpCustomers(group)">
            <mat-icon class="{{checkIfSomeInGroupCarryOnOnly(group) ? 'booking-icon-active' : null}}"
              matTooltip="{{checkIfSomeInGroupCarryOnOnly(group) ? 'Minst en bokning med bara handbagage' : 'Bara bokningar med incheckat bagage'}}">business_center</mat-icon>
          </span>

          <span class="booking-info-icon" *ngIf="isGroupArrival(group) && !isGroupPickedUpCustomers(group)">
            <mat-icon
              class="{{group.firstBagDate || group.lastBagDate ? 'booking-icon-active first_bag' : 'first_bag'}}"
              matTooltip="{{group.lastBagDate ? 'Sista bagage på band' : group.firstBagDate ? 'Första bagage på band' : 'Bandet kör inte'}}">work</mat-icon>
          </span>

          <span class="booking-info-icon" *ngIf="!isGroupPickedUpCustomers(group)">
            <mat-icon class="{{checkIfSomeInGroupHasComment(group) ? 'booking-icon-active' : null}}"
              matTooltip="{{checkIfSomeInGroupHasComment(group) ? 'Minst en bokning med en kommentar' : 'Inga kommentarer'}}">chat_bubble</mat-icon>
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-bookings-table *ngIf="group.bookings" [bookings]="group.bookings"
      [topic]="isGroupArrival(group) ? 'arrival' : 'departure'"
      [showCommentsColumn]="checkIfSomeInGroupHasComment(group) && !printGroups"
      [showCarWashColumn]="(checkIfSomeInGroupHasCarWash(group) || checkIfSomeInGroupHasCharge(group) || checkIfSomeInGroupHasTireRepair(group)) && !printGroups"
      [showTravelHomeFromColumn]="isGroupPickedUpCustomers(group)"
      [showParkTimeColumn]="isGroupCallCustomers(group) && !printGroups">
    </app-bookings-table>
  </mat-expansion-panel>
</mat-accordion>

<div class="empty-content" *ngIf="groupedBookings.length < 1 && !errorCode">
  <p>Inga körningar de närmsta timmarna</p>
  <mat-icon aria-hidden="false" fontIcon="coffee"></mat-icon>
</div>

<div class="error-content" *ngIf="errorCode && errorCode >= 400 && groupedBookings.length < 1">
  <p>Någonting gick fel vid hämtning av bokningar. Ladda om sidan för att göra ett nytt försök.</p>
  <p>Om problemet kvarstår, kontakta Rely IT för support.</p>
</div>