import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import {
  FeatureDTO,
  MainFeatureDTO,
  PriceDTO,
  PriceListDTO,
  ResourceDTO,
} from 'parking-sdk';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  GetAllFeatures,
  GetFeatures,
  GetResources,
} from 'src/app/core/states/pricelist/pricelist.actions';
import { PricelistState } from 'src/app/core/states/pricelist/pricelist.state';

@Component({
  selector: 'app-create-pricelist',
  templateUrl: './create-pricelist.component.html',
  styleUrls: ['./create-pricelist.component.scss'],
})
export class CreatePricelistComponent implements OnInit {
  pricelistForm?: FormGroup;
  resourceOptions?: ResourceDTO[];
  newPriceList?: PriceListDTO;

  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CreatePricelistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private formBuilder: FormBuilder
  ) {
    this.store
      .select(PricelistState.currentResources)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => (this.resourceOptions = value));
  }

  ngOnInit(): void {
    this.store.dispatch(new GetResources());
    this.store.dispatch(new GetAllFeatures());

    /* Create form */
    this.pricelistForm = this.formBuilder.group({
      name: new FormControl<string | undefined>(undefined, Validators.required),
      validFrom: new FormControl<Date | undefined>(
        new Date(),
        Validators.required
      ),
      validTo: new FormControl<Date | undefined>(undefined),
      resource: new FormControl<ResourceDTO | undefined>(undefined),
      selectedType: new FormControl<'feature' | 'resource'>('feature'),
    });

    /* Listen to changes */
    this.pricelistForm.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.updatePricelist();
      });
  }

  updatePricelist(): void {
    this.newPriceList = {
      name: this.pricelistForm?.controls['name'].value,
      validFrom: this.pricelistForm?.controls['validFrom'].value,
      validTo: this.pricelistForm?.controls['validTo'].value,
      prices:
        this.pricelistForm?.controls['selectedType'].value === 'resource'
          ? this.getDefaultResourcePrices()
          : [],
      resource: this.pricelistForm?.controls['resource'].value,
    };
  }

  getDefaultResourcePrices(): PriceDTO[] {
    let prices: PriceDTO[] = [];

    for (let i = 1; i <= 32; i++) {
      prices.push({
        days: i,
        price: i === 32 ? 200 : 100,
        daysOperator: i === 32 ? 1 : 0,
      });
    }

    return prices;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onCreateClick() {
    this.dialogRef.close(this.newPriceList);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
