import { Component, Input } from '@angular/core';
import {
  BookingDTO,
  FlightBookingDTO,
  HourBookingDTO,
  SimpleBookingDTO,
  SimpleHourBookingDTO,
} from 'parking-sdk';
import { Subject } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';

@Component({
  selector: 'app-current-bookings',
  templateUrl: './current-bookings.component.html',
  styleUrls: ['./current-bookings.component.scss'],
})
export class CurrentBookingsComponent {
  @Input() groupedBookings: FlightBookingDTO[] | HourBookingDTO[] = [];
  @Input() errorCode?: number;
  @Input() printGroups?: boolean = false;
  expandedPanelState: { [key: string]: boolean } = {};
  private onDestroy$ = new Subject<void>();
  constructor(private dateService: DateService) {}

  formatDate(date: string | Date | undefined): string {
    if (!date) return '';
    return this.dateService.formatDate(date);
  }

  formatTime(date: string | Date | undefined): string {
    if (!date) return '';
    return this.dateService.formatTime(date);
  }

  getTotalPassengers(group: FlightBookingDTO | HourBookingDTO): number {
    let result = 0;
    group.bookings?.forEach(
      (booking: SimpleBookingDTO | SimpleHourBookingDTO) =>
        (result += booking.qtyPersons || 0)
    );

    return result;
  }

  getTotalPickups(group: FlightBookingDTO | HourBookingDTO): number {
    let result = 0;
    group.bookings?.forEach(
      (booking: SimpleBookingDTO | SimpleHourBookingDTO) =>
        (result += booking.qtyPersonsPickedUp || 0)
    );
    return result;
  }

  getTotalDropOffs(group: FlightBookingDTO | HourBookingDTO): number {
    let result = 0;
    group.bookings?.forEach(
      (booking: SimpleBookingDTO | SimpleHourBookingDTO) =>
        (result += booking.qtyPersonsDroppedOff || 0)
    );
    return result;
  }

  displayPassengerStatus(group: FlightBookingDTO | HourBookingDTO): string {
    const totalPickups = this.getTotalPickups(group);
    const totalDropOffs = this.getTotalDropOffs(group);
    const totalPassengers = this.getTotalPassengers(group);
    const isGroupArrival = this.isGroupArrival(group);

    if (
      this.checkPlaneHasLanded(group) ||
      (totalPickups > 0 &&
        isGroupArrival &&
        !this.isGroupPickedUpCustomers(group))
    ) {
      return `${totalPickups} / ${totalPassengers}`;
    } else if (
      totalDropOffs > 0 &&
      !isGroupArrival &&
      !this.isGroupDroppedOffCustomers(group)
    ) {
      return totalDropOffs + ' / ' + totalPassengers;
    } else {
      return `${totalPassengers}`;
    }
  }

  checkIfSomeInGroupInGarage(group: any): boolean {
    return group.bookings
      ? group.bookings.some(
          (booking: BookingDTO) => booking.resource?.name === 'PARKING_GARAGE'
        )
      : false;
  }

  checkIfSomeInGroupHasCarWash(group: any): boolean {
    return group.bookings
      ? group.bookings.some((booking: any) => booking.carWash)
      : false;
  }

  checkIfSomeInGroupHasCharge(group: any): boolean {
    return group.bookings
      ? group.bookings.some((booking: any) => booking.garageService)
      : false;
  }

  checkIfSomeInGroupHasTireRepair(group: any): boolean {
    return group.bookings
      ? group.bookings.some((booking: any) => booking.tireRepair)
      : false;
  }

  checkIfSomeInGroupCarryOnOnly(group: any): boolean {
    return group.bookings
      ? group.bookings.some((booking: any) => booking.handLuggageOnly == true)
      : false;
  }

  checkIfSomeInGroupWantCarSeat(group: any): boolean {
    return group.bookings
      ? group.bookings.some((booking: any) => booking.childSafetySeat)
      : false;
  }

  checkIfSomeInGroupHasComment(group: any): boolean {
    return group.bookings
      ? group.bookings.some(
          (booking: any) => booking.comment && booking.comment.length > 0
        )
      : false;
  }

  checkIfSomeInGroupHasDemand(group: any): boolean {
    return group.bookings
      ? group.bookings.some((booking: any) => booking.amountToPay > 0)
      : false;
  }

  isGroupDeparture(group: object): boolean {
    return 'hourBookingType' in group;
  }

  isGroupArrival(group: object): boolean {
    return 'flightNumber' in group;
  }

  isGroupCallCustomers(group: FlightBookingDTO) {
    return !group.flightNumber && group.departureAirport
      ? group.departureAirport === 'Ringkunder'
      : false;
  }

  isGroupPickedUpCustomers(group: FlightBookingDTO) {
    return !group.flightNumber && group.departureAirport
      ? group.departureAirport === 'Hämtade'
      : false;
  }

  isGroupDroppedOffCustomers(group: any) {
    //HourBookingDTO
    return group.hourBookingType === 'DROPPED_OFF';
  }

  isNoShowsCustomers(group: any) {
    //HourBookingDTO
    return group.hourBookingType === 'NO_SHOWS';
  }

  checkPlaneHasLanded(group: FlightBookingDTO) {
    return group.flightLegStatus ? group.flightLegStatus === 'LAN' : false;
  }

  handleExpansionPanelState(
    group: FlightBookingDTO | HourBookingDTO,
    isOpen: boolean
  ): void {
    const key = this.createExpansionPanelKey(group);
    if (key)
      this.expandedPanelState = { ...this.expandedPanelState, [key]: isOpen };
  }

  isExpansionPanelOpen(group: FlightBookingDTO | HourBookingDTO): boolean {
    const key = this.createExpansionPanelKey(group);
    const state = key ? this.expandedPanelState[key] : false;
    return state;
  }

  createExpansionPanelKey(group: FlightBookingDTO | HourBookingDTO) {
    let key: string | undefined;
    if ('flightNumber' in group) {
      key = group.flightNumber || group.departureAirport;
    } else if ('hourBookingType' in group) {
      key = group.departureDate?.toString() || group.hourBookingType;
    }
    return key;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
