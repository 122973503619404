export class GetCurrentBookings {
  static readonly type = '[Bookings] Get Current Bookings';
  constructor() {}
}
export class GetBooking {
  static readonly type = '[Bookings] Get Booking';
  constructor(public id: number) {}
}
export class GetEditBooking {
  static readonly type = '[Bookings] Get Booking to edit';
  constructor(public id: number | undefined) {}
}
export class DoneEditBooking {
  static readonly type = '[Bookings] Reset Booking to edit';
  constructor() {}
}

export class FindBookings {
  static readonly type = '[Bookings] Find Bookings';
  constructor(public searchTerm: string) {}
}

export class FindBookingsByDirection {
  static readonly type = '[Bookings] Find Bookings By Direction';
  constructor(
    public searchTerm: string,
    public direction: 'arrival' | 'departure'
  ) {}
}

export class GetUpcomingTimeRange {
  static readonly type = '[Bookings] Get Upcoming Time Range';
  constructor() {}
}

export class BookingSearchListener {
  static readonly type = `[Booking] Listener for Search bookings history and upcoming`;
  constructor(public term?: string) {}
}

export class UpdateQtyPersonsCheckedOff {
  static readonly type = `[Booking] Update Qty Persons Picked Up or Dropped Off`;
  constructor(
    public direction: 'departure' | 'arrival',
    public bookingId: number,
    public qtyPersonsCheckedOff: number
  ) {}
}

export class QtyPersonCheckOffListener {
  static readonly type = `[Booking] Listener for Update of Qty Persons Picked Up or Dropped Off`;
  constructor(
    public direction: 'departure' | 'arrival',
    public bookingId: number,
    public qtyPersonsCheckedOff: number
  ) {}
}

export class GetGarageBookings {
  static readonly type = '[Bookings] Get Garage Bookings';
  constructor(
    public pageIndex: number,
    public pageSize: number,
    public sortColumn: string = 'arrivalDate',
    public sortOrder: string = 'asc',
    public searchTerm?: string
  ) {}
}

export class GetAllBookings {
  static readonly type = '[Bookings] Get All Bookings';
  constructor(
    public pageIndex: number,
    public pageSize: number,
    public startDate?: Date,
    public endDate?: Date,
    public dateFilterType?: 'arrival' | 'departure',
    public sortColumn?: string,
    public sortOrder?: string,
    public searchTerm?: string,
    public garageFilter?: string
  ) {}
}

export class CancelBooking {
  static readonly type = '[Bookings] Cancel booking';
  constructor(public bookingId: number) {}
}

export class ResetBookingState {
  static readonly type = '[Bookings] Reset bookings state';
  constructor() {}
}
