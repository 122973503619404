import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmGuardService {
  constructor(private dialog: MatDialog) {}

  canDeactivate(component: any): Observable<boolean> {
    if (component.hasChanges) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Är du säker på att du vill lämna sidan?',
          message: [`Dina ändringar kommer inte att sparas.`],
          yesBtnText: 'Lämna'
        },
      });

      return confirmDialog.afterClosed()

    } else {
      return of(true)
    }
  }
}
