<div>
    <h3>Logga in</h3>
    <form *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
        <label aria-label="användarnamn">
            <input formControlName="username" (focus)="notFound = undefined" id="username" type="text" placeholder="e-post">
        </label>
        <label  aria-label="lösenord">
            <input formControlName="password" (focus)="notFound = undefined" id="password" type="password" placeholder="lösenord">
        </label>
        <p class="form-error">{{notFound || ''}}</p>
        <button class="button" type="submit" [disabled]="!loginForm.valid">
            Logga in
        </button>
    </form>
    <div class="forgotPassword">
        <button mat-button (click)="forgotPassword()">Glömt lösenord</button>
    </div>
    
</div>