import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { ArticleReportDTO, DefaultService } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-daily-article-report',
  templateUrl: './daily-article-report.component.html',
  styleUrls: ['./daily-article-report.component.scss'],
})
export class DailyArticleReportComponent {
  articleReport?: ArticleReportDTO[];
  startDate = new FormControl(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );
  endDate = new FormControl(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );

  private onDestroy$ = new Subject<void>();

  constructor(
    private defaultService: DefaultService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.getArticleReport();
  }

  ngAfterViewInit() {
    this.startDate.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.getArticleReport());

    this.endDate.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.getArticleReport());
  }

  getArticleReport(): void {
    this.startDate.value &&
      this.endDate.value &&
      this.defaultService
        .getArticleReport(
          dayjs(this.startDate.value)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toDate(),
          dayjs(this.endDate.value)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .add(1, 'day')
            .toDate()
          )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((value) => {
          this.articleReport = value;
        });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  downloadReport() {
    if (this.startDate.value && this.endDate.value)
      this.exportService.getReportArticles(
        dayjs(this.startDate.value)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toDate(),
      dayjs(this.endDate.value)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(1, 'day')
        .toDate()
      );
  }

  getTotalByType(type: 'payments' | 'refunds' | 'brutto' | 'netto' | 'vat') {
    return this.articleReport?.map(t => t[type]).reduce((acc, value) => (acc || 0) + (value || 0), 0) || 0;
  }
}
