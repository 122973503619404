<h3 mat-dialog-title>Återuppta betalning</h3>
<div mat-dialog-content class="content">
  <div class="loading-wrapper" *ngIf="loading">
    <span>Ansluter...</span> <mat-spinner diameter="25" color="primary"></mat-spinner>
  </div>
  <app-pax-payment-status (finished)="finalStatus($event)"></app-pax-payment-status>

  <p *ngIf="currentPaymentStatus">{{currentPaymentStatus}}</p>

  <button mat-button *ngIf="currentPaymentStatus" (click)="closeContinuePaxPayment()">Stäng</button>
</div>