import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import {
  ContinuePaymentPax,
  ResetOrderTransState,
} from 'src/app/core/states/orders/orders.action';

@Component({
  selector: 'app-continue-pax-payment',
  templateUrl: './continue-pax-payment.component.html',
  styleUrls: ['./continue-pax-payment.component.scss'],
})
export class ContinuePaxPaymentComponent {
  currentPaymentStatus?: string;
  statusResult?: any;
  loading = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<ContinuePaxPaymentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: number;
      uti: string;
    }
  ) {}

  ngOnInit() {
    if (this.data.uti && this.data.orderId) {
      this.loading = true;
      this.store
        .dispatch(new ContinuePaymentPax(this.data.orderId, this.data.uti))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {});
    } else {
      console.error('No uti available: ', this.data.uti);
    }
  }

  finalStatus(e: any) {
    this.loading = false;
    if (e.cancelled) {
      this.currentPaymentStatus =
        'Transaktion AVBRUTEN. Ingen transaktion har genomförts.';
      this.statusResult = { cancelled: true };
    } else if (e.approved) {
      this.currentPaymentStatus = 'Transaktion GODKÄND. Transaktion genomförd.';
      this.statusResult = { approved: true };
    } else if (e.declined) {
      this.currentPaymentStatus =
        'Transaktion MEDGES EJ. Ingen transaktion har genomförts.';
      this.statusResult = { declined: true };
    } else if (e.noConnection) {
      this.currentPaymentStatus =
        'Ingen kontakt med terminal. Gick inte att avgöra transaktionens status, detta måste följas upp.';
      this.store.dispatch(new ResetOrderTransState());
      this.statusResult = { noConnection: true };
    } else if (e.ended) {
      this.currentPaymentStatus =
        'Transaktion AVSLUTAD. Ingen transaktion har genomförts.';
      this.statusResult = { ended: true };
    }
  }

  closeContinuePaxPayment(): void {
    this.dialogRef.close(this.statusResult);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
