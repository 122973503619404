import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import dayjs from 'dayjs';
import {
  BookingDTO,
  SimpleBookingDTO,
  SimpleHourBookingDTO,
} from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';
import { GetBooking } from 'src/app/core/states/bookings/booking.actions';

@Component({
  selector: 'app-bookings-table',
  templateUrl: './bookings-table.component.html',
  styleUrls: ['./bookings-table.component.scss'],
})
export class BookingsTableComponent {
  @Input() bookings!:
    | BookingDTO[]
    | SimpleBookingDTO[]
    | SimpleHourBookingDTO[];
  @Input() topic?: 'arrival' | 'departure' | 'garage' | 'all';
  @Input() totalPassengers?: number;
  @Input() coloredSearchResult: boolean = false;
  @Input() showCarWashColumn?: Boolean;
  @Input() showTravelHomeFromColumn?: Boolean;
  @Input() showCommentsColumn?: Boolean;
  @Input() showParkTimeColumn?: Boolean;
  @Input() sortOrder: 'asc' | 'desc' | undefined = undefined;
  @Input() prevTargetColumn: string | undefined = undefined;
  @Output() closeDrawer: EventEmitter<any> = new EventEmitter();
  @Output() sortColumnClick: EventEmitter<any> = new EventEmitter();
  displayedColumns?: string[];
  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.displayedColumns =
      this.topic === 'arrival'
        ? this.arrivalColumns()
        : this.topic === 'departure'
        ? this.departureColumns()
        : this.topic === 'garage'
        ? [
            'bookingNumber',
            'registrationNumber',
            'flightNumber',
            'departureDate',
            'arrivalDate',
            'carWash',
            'qtyPersons',
            'comments',
          ]
        : [
            'bookingNumber',
            'registrationNumber',
            'location',
            'flightNumber',
            'departureDate',
            'arrivalDate',
            'qtyPersons',
            'comments',
          ];
  }

  showBooking(row: any) {
    this.store
      .dispatch(new GetBooking(row.bookingId))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();

    // open single page view
    this.router.navigate(['bokning', row.bookingId]);
  }

  formatDate(date: string | Date): string {
    return this.dateService.formatDate(date);
  }

  formatMonthAndDay(date: string | Date): string {
    return dayjs(date).format('DD/MM');
  }

  formatTime(date: string | Date): string {
    return this.dateService.formatTime(date);
  }
  close() {
    this.closeDrawer.emit();
  }

  arrivalColumns() {
    const defaults = [
      'bookingNumber',
      'registrationNumber',
      'qtyPersons',
      'childSafetySeat',
      'paymentStatus',
      'location',
    ];
    if (!this.showTravelHomeFromColumn) defaults.push('handLuggage');
    if (this.showTravelHomeFromColumn)
      defaults.splice(2, 1, 'flightNumber', 'travelHomeFrom'); // Replace childeseat if picked up
    if (this.showParkTimeColumn) defaults.splice(1, 0, 'parkTime');
    if (this.showCarWashColumn) defaults.splice(5, 0, 'carWash'); // Put car wash column before handluggage column to match icons order
    if (this.showCommentsColumn) defaults.push('comments');
    defaults.push('drivenPassengers');

    return defaults;
  }

  departureColumns() {
    const defaults = [
      'bookingNumber',
      'registrationNumber',
      'departureDate',
      'qtyPersons',
      'flightNumber',
      'childSafetySeat',
      'paymentStatus',
      'location',
    ];

    if (this.showCarWashColumn) defaults.push('carWash');
    if (this.showCommentsColumn) defaults.push('comments');
    defaults.push('drivenPassengers');
    return defaults;
  }

  preventRowClick(event: any) {
    event.stopPropagation();
  }

  sortColumn(event: Event) {
    const target = event.target as HTMLTableCellElement;
    const targetColumn = target.getAttribute('data-column');
    switch (this.sortOrder) {
      case undefined:
        this.sortColumnClick.emit({
          column: targetColumn ? targetColumn : undefined,
          sort: 'asc',
        });
        break;
      case 'asc':
        this.sortColumnClick.emit({
          column: targetColumn || undefined,
          sort: this.prevTargetColumn === targetColumn ? 'desc' : 'asc',
        });
        break;
      case 'desc':
        this.sortColumnClick.emit({
          column:
            this.prevTargetColumn === targetColumn || !targetColumn
              ? undefined
              : targetColumn,
          sort: this.prevTargetColumn === targetColumn ? undefined : 'asc',
        });
        break;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
