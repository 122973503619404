import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Store } from '@ngxs/store';
import { DefaultService, PartnerDTO, RoleDTO } from 'parking-sdk';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GetRoles } from 'src/app/core/states/users/user.actions';
import { UsersState } from 'src/app/core/states/users/users.state';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  @ViewChild('partnerSelect') partnerSelect?: MatSelect;
  userForm!: FormGroup;
  roleOptions$: Observable<RoleDTO[]>;
  partners?: PartnerDTO[];
  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CreateUserComponent>,
    private fb: FormBuilder,
    private store: Store,
    private defaultService: DefaultService
  ) {
    this.roleOptions$ = this.store.select(UsersState.currentRoles);
  }

  ngOnInit() {
    this.store.dispatch(GetRoles);
    this.userForm = this.fb.group({
      username: new FormControl<string | undefined>(undefined, [
        Validators.required,
        Validators.email,
      ]),
      roles: new FormControl<string[] | undefined>(undefined, [
        Validators.required,
      ]),
      newPassword: new FormControl<string | undefined>(undefined, [
        Validators.minLength(6),
      ]),
      partner: new FormControl<PartnerDTO | undefined>(undefined),
    });

    this.userForm.controls['roles'].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((changes: any[]) => {
        if (changes?.some((change) => change.name === 'ROLE_PARTNER')) {
          this.partnerSelect?.close();
          this.getPartners();
        } else {
          this.partners = undefined;
          this.userForm.controls['partner'].setValue(undefined);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onCreateClick() {
    if (this.userForm.valid) {
      /* If empty password, set undefined. Backend will send email to user with password link if password is undefined. */
      let newPassword = this.userForm.get('newPassword')?.value;
      if (newPassword && newPassword.trim() === '') {
        newPassword = undefined;
      }

      /* If role is partner, connect partner obj to role */
      let roles: RoleDTO[] = this.userForm.get('roles')?.value;
      if (this.userForm.controls['partner'].value) {
        roles = roles.map((role) => {
          if (role.name === 'ROLE_PARTNER') {
            return {
              ...role,
              partner: this.userForm.controls['partner'].value,
            };
          } else return role;
        });
      }

      this.dialogRef.close({
        username: this.userForm.get('username')?.value,
        roles: roles,
        newPassword: newPassword,
      });
    }
  }

  getPartners(): void {
    this.defaultService
      .findPartners()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((partners) => {
        this.partners = partners;
      });
  }
}
