import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultService } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';

const matchingFieldsValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const firstPass = control.get('newPass');
  const secondPass = control.get('repeatPass');

  return firstPass && secondPass && firstPass.value !== secondPass.value ? {noMatchPass: true} : null;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetToken: string = '';
  validToken = true;
  newPassForm?: FormGroup;

  private onDestroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute, 
    private defaultService: DefaultService,
    private router: Router) {
    this.resetToken = this.route.snapshot.paramMap.get('token')!;
    this.defaultService.passwordResetRequestValidation({resetToken: this.resetToken}).pipe(takeUntil(this.onDestroy$)).subscribe((res) => {
      this.validToken = res;
    });
  }

  ngOnInit(): void {
    

    this.newPassForm = new FormGroup({
      newPass: new FormControl('', Validators.required),
      repeatPass: new FormControl('', Validators.required)
    }, {validators: matchingFieldsValidator});
  }

  submit() {
    this.defaultService.resetPasswordAdmin(
      {
        resetToken: this.resetToken, 
        newPassword: this.newPassForm?.value.newPass
      }
    ).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.router.navigate(['../home']);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
