import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RegisterOpenedReceiptComponent } from './register-opened-receipt/register-opened-receipt.component';
import { IframeService } from 'src/app/core/services/iframe.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { DrawerServiceService } from 'src/app/core/services/drawer-service.service';
import { CashDrawerActionDTO, DefaultService } from 'parking-sdk';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  openRegisterForm?: FormGroup;
  @Input() drawerActive = false;
  private onDestroy$ = new Subject<void>();

  /* iframe */
  doc: any;
  @ViewChild('registerOpenedReceipt', { read: ElementRef, static: false })
  iframe: ElementRef | undefined;
  dynamicRef?: ComponentRef<RegisterOpenedReceiptComponent>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private iframeService: IframeService,
    private changeDetectorRef: ChangeDetectorRef,
    private defaultService: DefaultService
  ) {}

  ngOnInit() {
    this.openRegisterForm = new FormGroup({
      comment: new FormControl<string | undefined>(undefined),
    });
  }

  ngOnChanges() {
    if (!this.drawerActive) {
      this.openRegisterForm?.controls['comment'].setValue(undefined);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onFrameLoad() {
    setTimeout(() => {
      // Make sure it ends up first in queue and runs as soon as possible  when the call stack is empty
      // https://stackoverflow.com/questions/9083594/call-settimeout-without-delay
      this.doc = this.iframe!.nativeElement.contentWindow.document;
      this.createComponent();
    });
  }

  /* TODO: Same code here as in checkout, can we put it in iframe service? */
  createComponent() {
    this.dynamicRef = this.viewContainerRef.createComponent(
      RegisterOpenedReceiptComponent
    );
    this.dynamicRef.instance.comment =
      this.openRegisterForm?.controls['comment'].value;
    this.dynamicRef.changeDetectorRef.detectChanges();
    this.doc.body.appendChild(this.dynamicRef.location.nativeElement);
    this.iframeService.attachStyles(this.doc);
  }

  async printReceipt() {
    this.reloadReceipt();
    await this.awaitReceiptChanges();
    
    let cashDrawerAction: CashDrawerActionDTO = {
      cashDrawerActionType: 'OTHER',
      comment: this.openRegisterForm?.controls['comment']?.value,
    };

    cashDrawerAction && this.defaultService.addCashDrawerActionAdmin(cashDrawerAction).pipe(takeUntil(this.onDestroy$)).subscribe();
    
    (
      document.getElementById('registerOpenedReceipt') as HTMLIFrameElement
    ).contentWindow?.print();
  }

  reloadReceipt() {
    this.dynamicRef!.instance.comment =
      this.openRegisterForm?.controls['comment'].value;
    this.dynamicRef!.changeDetectorRef.detectChanges();
  }

  awaitReceiptChanges(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        resolve();
      }, 0);
    });
  }
}
