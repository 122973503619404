import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  DefaultService,
  OrderDTO,
  OrderItemDTO,
  PaymentDTO,
  PaymentInvoiceDTO,
} from 'parking-sdk';
import { Subject, first, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { CashPaymentComponent } from 'src/app/components/payment/cash-payment/cash-payment.component';
import { ContinuePaxPaymentComponent } from 'src/app/components/payment/continue-pax-payment/continue-pax-payment.component';
import { PaxPaymentComponent } from 'src/app/components/payment/pax-payment/pax-payment.component';
import { ZeroPaymentComponent } from 'src/app/components/payment/zero-payment/zero-payment.component';
import { DateService } from 'src/app/core/services/date.service';
import {
  CheckoutOrderCash,
  CheckoutOrderPayLater,
  DeleteOrder,
  GetOrder,
} from 'src/app/core/states/orders/orders.action';
import { OrdersState } from 'src/app/core/states/orders/orders.state';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  confirmReload(event: BeforeUnloadEvent) {
    event.preventDefault();
  }

  @ViewChild('iframe', { read: ElementRef, static: false }) iframe:
    | ElementRef
    | undefined;

  refund = false;
  allowPayments = true;

  currentOrder: OrderDTO = {};
  newOrder = true; // Listened to by checkout guard
  paymentMethod: FormGroup;
  invoice?: PaymentInvoiceDTO;
  paymentFinished = false; // Listened to by checkout guard
  zeroCheckout = false;
  noReset = false; // Listened to by checkout guard
  invalidFormMessage: string = '';
  eInvoiceCheck = new FormControl<boolean>(true);
  startedPayments?: PaymentDTO[];

  loading = false;
  emailSent = false;
  emailMsg?: string;

  /* Forms */
  invoiceForm?: FormGroup;
  discountForm: FormGroup;
  discountedItem?: OrderItemDTO;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private defaultService: DefaultService,
    private cdr: ChangeDetectorRef,
    private dateService: DateService
  ) {
    this.store
      .select(OrdersState.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order) => {
        this.currentOrder = order;
      });
    this.paymentMethod = this.formBuilder.group({
      method: new FormControl<string | undefined>(undefined),
    });
    this.store
      .selectOnce(OrdersState.newOrder)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((newOrder) => {
        this.newOrder = newOrder; // This is always true..
      });

    this.discountForm = this.formBuilder.group({
      discountAmount: new FormControl<number | undefined>(
        this.currentOrder.discount || undefined,
        [Validators.required]
      ),
      discountType: new FormControl<string>(
        this.currentOrder.discountType === 'PERCENT' ? '%' : 'kr',
        [Validators.required]
      ),
      discountComment: new FormControl<string | undefined>(
        this.currentOrder.discountComment || undefined,
        [Validators.required]
      ),
    });
  }

  ngOnInit() {
    this.reloadOrder();
    this.setInvoiceForm();
    this.getUnfinishedPayments();
    if (
      this.currentOrder?.amountToPay !== undefined &&
      this.currentOrder.amountToPay < 0
    )
      this.refund = true;

    this.paymentMethod.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.invalidFormMessage = '';
      });
    this.discountForm?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.invalidFormMessage = '';
      });
    this.invoiceForm?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.invalidFormMessage = '';
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getUnfinishedPayments() {
    this.currentOrder.orderId &&
      this.defaultService
        .getUnfinishedPayments(this.currentOrder.orderId)
        .pipe(first())
        .subscribe((response) => {
          if (response?.length) {
            this.startedPayments = response;
            this.allowPayments = false;
          } else {
            this.startedPayments = undefined;
            this.allowPayments = true;
          }
        });
  }

  continuePayment(uti?: string) {
    let dialogRef = this.dialog.open(ContinuePaxPaymentComponent, {
      width: '30vw',
      data: { orderId: this.currentOrder.orderId, uti },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result && result.approved) {
          this.store
            .dispatch(new GetOrder(this.currentOrder.orderId!))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.snackBar.open('Betalning behandlad', 'OK', {
                duration: 3000,
              });

              if (this.currentOrder.amountToPay === 0) {
                this.paymentFinished = true;
              }

              this.getUnfinishedPayments();
            });
        } else if (result?.noConnection) {
          this.allowPayments = true;
          this.snackBar.open('Kunde inte ansluta till terminalen', 'OK', {
            duration: 10000,
          });
        } else {
          this.store
            .dispatch(new GetOrder(this.currentOrder.orderId!))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.getUnfinishedPayments();
            });
        }
      });
  }

  reloadOrder(): void {
    this.store
      .dispatch(new GetOrder(this.currentOrder.orderId!))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.currentOrder.amountToPay! <= 0) {
          this.paymentFinished = false;
          this.zeroCheckout = true;
        } else {
          this.zeroCheckout = false;
        }
      });
  }

  containsOnlyPrepaid(): boolean {
    let result = true;
    this.currentOrder.orderItems?.forEach((item) => {
      if (item.booking || item.feature) {
        result = false;
      }
    });
    return result;
  }

  updateInvoice(): void {
    this.invalidFormMessage = '';
    if (this.invoiceForm) {
      const sanitizedOrgNum = this.invoiceForm.controls[
        'organizationNumber'
      ].value.replace('-', '');
      this.invoice = {
        ...this.invoice,
        organizationNumber: this.invoiceForm.controls['organizationNumber']
          .value
          ? sanitizedOrgNum
          : undefined,
        reference: this.invoiceForm.controls['reference'].value || undefined,
        name: this.invoiceForm.controls['name'].value || undefined,
        email: this.invoiceForm.controls['email'].value || undefined,
        phone: this.invoiceForm.controls['phone'].value || undefined,
        invoiceEmail:
          this.invoiceForm.controls['invoiceEmail'].value || undefined,
        address: this.invoiceForm.controls['address'].value || undefined,
        zip: this.invoiceForm.controls['zip'].value || undefined,
        city: this.invoiceForm.controls['city'].value || undefined,
        country: this.invoiceForm.controls['country'].value || undefined,
      };
    }
  }

  setDiscountItem(item: OrderItemDTO) {
    if (this.discountedItem === item) {
      this.discountedItem = undefined;
    } else {
      this.discountedItem = item;
    }
  }

  applyDiscount() {
    const item = this.currentOrder.orderItems?.find(
      (object) => object === this.discountedItem
    )!;
    item.discount = this.discountForm.controls['discountAmount'].value;
    item.discountComment = this.discountForm.controls['discountComment'].value;
    this.defaultService
      .updateOrderItemDiscountAdmin(
        this.currentOrder.orderId!,
        item.orderItemId!,
        item
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order) => {
        if (order?.amountToPay !== undefined && order.amountToPay === 0)
          this.zeroCheckout = true;
        else this.zeroCheckout = false;

        if (order?.amountToPay !== undefined && order.amountToPay < 0)
          this.refund = true;
        else this.refund = false;

        // Update payment methods based on new amount
        this.reloadOrder();
      });
    this.discountedItem = undefined;
  }

  removeDiscount(item: OrderItemDTO) {
    item.discount = 0;
    item.discountComment = undefined;

    this.defaultService
      .updateOrderItemDiscountAdmin(
        this.currentOrder.orderId!,
        item.orderItemId!,
        item
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order) => {
        if (order?.amountToPay !== undefined && order.amountToPay > 0)
          this.zeroCheckout = false;
        else this.zeroCheckout = true;

        if (order?.amountToPay !== undefined && order.amountToPay < 0)
          this.refund = true;
        else this.refund = false;

        // Update payment methods based on new amount
        this.reloadOrder();
      });
  }

  awaitReceiptChanges(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.cdr.detectChanges();
      setTimeout(() => {
        resolve();
      }, 0);
    });
  }

  printReceipt() {
    this.defaultService
      .addCashDrawerActionAdmin({
        cashDrawerActionType: 'PAYMENT',
        comment: 'Payment',
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();

    let latestPayment =
      (this.currentOrder?.payments?.length &&
        this.currentOrder.payments[this.currentOrder.payments.length - 1]) ||
      undefined;

    latestPayment?.paymentId &&
      this.defaultService
        .getPaymentReceiptHtmlAdmin(latestPayment.paymentId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((v) => {
          if (v.html) {
            if (this.iframe) {
              this.iframe.nativeElement.contentWindow.document.open();
              this.iframe.nativeElement.contentWindow.document.close();
              this.iframe.nativeElement.contentWindow.frames.document.body.insertAdjacentHTML(
                'afterbegin',
                v.html
              );
              this.awaitReceiptChanges().then(() => {
                this.iframe?.nativeElement.contentWindow.print();
              });
            }
          }
        });
  }

  handlePayment() {
    this.invalidFormMessage = '';
    let order = this.currentOrder;

    if (this.zeroCheckout) {
      let dialogRef = this.dialog.open(ZeroPaymentComponent, {
        width: '30vw',
        data: { order },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result.success) {
            this.paymentFinished = true;

            this.store
              .dispatch(new GetOrder(order.orderId!))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                if (result.receipt) {
                  this.printReceipt();
                }
              });
            this.newOrder = true;
            this.snackBar.open('Ingen betalning behövs', 'OK', {
              duration: 3000,
            });
          }
        });
    } else if (this.paymentMethod.controls['method'].value === 'Kort') {
      let dialogRef = this.dialog.open(PaxPaymentComponent, {
        width: '30vw',
        data: { order },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result && result.accepted) {
            this.snackBar.open('Order betald', 'OK', { duration: 3000 });
            if (order.amountToPay! <= result.paidAmt) {
              this.paymentFinished = true;
            }
            this.store
              .dispatch(new GetOrder(order.orderId!))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                if (result.receipt) {
                  this.printReceipt();
                }
              });
            this.newOrder = true;
          } else if (result && result.cancelled) {
            this.snackBar.open('Order avbruten', 'OK', { duration: 3000 });
          } else if (result && result.declined) {
            this.snackBar.open('Medges ej - prova igen', 'OK', {
              duration: 10000,
            });
          } else if (result?.unavailable) {
            this.snackBar.open('Kunde inte ansluta till terminalen', 'OK', {
              duration: 10000,
            });
          }
        });
    } else if (this.paymentMethod.controls['method'].value === 'Kontant') {
      let dialogRef = this.dialog.open(CashPaymentComponent, {
        width: '30vw',
        data: { order },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          if (result.success) {
            if (order.amountToPay! <= result.paidAmt) {
              this.paymentFinished = true;
            }
            this.store
              .dispatch(new GetOrder(order.orderId!))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                if (result.receipt) {
                  this.printReceipt();
                }
              });
            this.newOrder = true;
            this.snackBar.open('Order betald', 'OK', { duration: 3000 });
          }
        });
    } else if (
      this.paymentMethod.controls['method'].value === 'Betala senare'
    ) {
      this.payLater(order);
    } else if (this.paymentMethod.controls['method'].value === 'Faktura') {
      if (!this.invoiceForm?.valid)
        this.invalidFormMessage = 'Ett eller flera fält är ogiltiga';
      else {
        const payment: PaymentDTO = {
          paymentMethod: { paymentMethodId: 'INVOICE' },
          paymentInvoice: this.invoice,
          transactionType: 'PAYMENT',
          amount: this.currentOrder.amountToPay,
        };

        // Use the same method as cash, only difference is invoice object
        this.store
          .dispatch(new CheckoutOrderCash(order.orderId!, payment))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.paymentFinished = true;
            this.newOrder = true;
            this.snackBar.open('Faktura sparad', 'OK', { duration: 3000 });
          });
        this.disableInvoiceForm();
      }
    }
  }

  payLater(order: OrderDTO) {
    this.store
      .dispatch(new CheckoutOrderPayLater(order.orderId!))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.paymentFinished = true;
        this.newOrder = true;
        this.snackBar.open('Order sparad för senare betalning', 'OK', {
          duration: 3000,
        });
      });
  }

  handleRefund() {
    this.noReset = true;
    this.paymentFinished = true;
    this.router.navigate(['aterbetalning']);
  }

  disableInvoiceForm(): void {
    if (this.invoiceForm) {
      this.invoiceForm.controls['organizationNumber'].disable();
      this.invoiceForm.controls['reference'].disable();
      this.invoiceForm.controls['name'].disable();
      this.invoiceForm.controls['email'].disable();
      this.invoiceForm.controls['phone'].disable();
      this.invoiceForm.controls['invoiceEmail'].disable();
      this.invoiceForm.controls['address'].disable();
      this.invoiceForm.controls['zip'].disable();
      this.invoiceForm.controls['city'].disable();
      this.invoiceForm.controls['country'].disable();
    }
  }

  cancelOrder() {
    const confDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Avbryt order',
        message: ['Är du säker på att du vill avbryta pågående order?'],
        yesBtnText: 'Ja',
        noBtnText: 'Nej',
      },
    });

    confDialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.store.dispatch(new DeleteOrder(this.currentOrder.orderId!));
          this.router.navigate(['../hem']);
        }
      });
  }

  /* TODO: Make child component of invoice, return value of the form when changes are made. */
  setInvoiceForm() {
    this.invoiceForm = this.formBuilder.group({
      organizationNumber: new FormControl<string | undefined>(undefined, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(12),
      ]),
      reference: new FormControl<string | undefined>(undefined),
      name: new FormControl<string | undefined>(undefined, [
        Validators.required,
      ]),
      email: new FormControl<string | undefined>(undefined, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl<string | undefined>(undefined, [
        Validators.required,
        Validators.minLength(7)
      ]),
      invoiceEmail: new FormControl<string | undefined>(undefined, [
        Validators.required,
        Validators.email,
      ]),
      address: new FormControl<string | undefined>(undefined, [
        Validators.required,
      ]),
      zip: new FormControl<string | undefined>(undefined, [
        Validators.required,
      ]),
      city: new FormControl<string | undefined>(undefined, [
        Validators.required,
      ]),
      country: new FormControl<string | undefined>('Sverige', [
        Validators.required,
      ]),
    });
  }

  switchInvoiceFormReq() {
    this.eInvoiceCheck.value
      ? this.invoiceForm?.controls['invoiceEmail'].setValidators([
          Validators.required,
        ])
      : this.invoiceForm?.controls['invoiceEmail'].clearValidators();

    this.invoiceForm?.controls['invoiceEmail'].updateValueAndValidity();
  }

  getBookingId(): number | undefined {
    const result = this.currentOrder.orderItems?.filter(
      (item) => item.orderItemType === 'RESOURCE'
    );
    return result?.[0].booking?.bookingId;
  }

  formatDate(date?: string | Date): string | undefined {
    return date && this.dateService.formatDate(date);
  }

  formatTime(date?: string | Date): string | undefined {
    return date && this.dateService.formatTime(date);
  }

  sendEmail() {
    const confDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Är du säker på att du vill skicka bokningsbekräftelse till kunden/kunderna?`,
        message: [
          `Kunden/kunderna kommer att få en bokningsbekräftelse till den angivna e-postadressen och det angivna telefonnumret i bokningen.`,
        ],
        yesBtnText: 'Skicka',
      },
    });

    confDialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          this.loading = true;
          const bookingItems = this.currentOrder.orderItems?.filter(
            (item) => item.orderItemType === 'RESOURCE'
          );
          bookingItems?.forEach((bookingItem) => {
            if (bookingItem.booking?.bookingId) {
              this.emailMsg = undefined;
              this.defaultService
                .sendBookingConfirmationAdmin(bookingItem.booking.bookingId)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                  next: () => {
                    this.emailSent = true;
                    this.emailMsg = 'Bokningsbekräftelse skickad!';
                    this.loading = false;
                  },
                  error: () => {
                    this.emailSent = false;
                    this.emailMsg =
                      'Det gick inte att skicka bokningsbekräftelsen, kontrollera e-postadressen och telefonnumret.';
                    this.loading = false;
                  },
                });
            } else {
              this.emailSent = false;
              this.emailMsg =
                'Det gick inte att skicka bokningsbekräftelsen, ladda om sidan och försök igen.';
            }
          });
        }
      });
  }

  havePrepaidTicket(order: OrderDTO): boolean {
    const prepaidTicketOrderItems = order.orderItems?.filter(
      (orderItem) =>
        orderItem.orderItemType === 'RESOURCE' &&
        orderItem.booking &&
        orderItem.booking.prepaidTicket
    );
    return prepaidTicketOrderItems!.length > 0;
  }

  hideRemoveDiscount(orderItem: OrderItemDTO) {
    return (
      orderItem.orderItemType === 'RESOURCE' && orderItem.booking?.prepaidTicket
    );
  }
}
