<ng-container *ngIf="! hideLayout">
  <header [ngClass]="{ 'logged-in-header': isAuthenticated }">
    <div class="logo-title-wrapper">
      <img src="../assets/images/lindskrog_logo.png" alt="Lindskrog logotyp" [routerLink]="['hem']" />
      <h1>
        <span>{{ !partner ?  title : 'i samarbete med ' + partner.name }}</span><span [ngClass]="{
            description: true,
            dev: description === 'utvecklingsmiljö'
          }" *ngIf="!isProd">{{ description }}
          <mat-icon *ngIf="description === 'utvecklingsmiljö'">build</mat-icon><mat-icon
            *ngIf="description === 'staging'">settings</mat-icon></span>
      </h1>
    </div>
    <button *ngIf="isAuthenticated" class="signout" (click)="logout()"><mat-icon aria-hidden="false"
        aria-label="Logga ut" fontIcon="person"></mat-icon>Logga ut {{user?.name || user?.username || ''}}</button>
  </header>

  <app-navbar *ngIf="isAuthenticated && !partner" [isAdmin]="isAdmin"></app-navbar>

  <mat-drawer-container class="drawer-container" [hasBackdrop]="true">
    <mat-drawer class="drawer" #drawer [mode]="'over'" (openedStart)="drawerActive = true"
      (closedStart)="drawerActive = false">
      <div class="drawer-wrapper" *ngIf="isAuthenticated">
        <div class="search-drawer">
          <button mat-button class="close-side-search-btn" (click)="drawer.toggle()">
            <mat-icon fontIcon="close"></mat-icon>Stäng
          </button>

          <app-side-menu-search *ngIf="drawerContent === 'search'" [drawerActive]="drawerActive"
            (closeDrawer)="drawer.close()"></app-side-menu-search>

          <app-register *ngIf="drawerContent === 'register'" [drawerActive]="drawerActive"></app-register>
        </div>
        <app-side-menu (toggleDrawer)="drawer.toggle()" (closeDrawer)="drawer.close()"
          [drawerActive]="drawerActive"></app-side-menu>
      </div>
    </mat-drawer>
    
    <mat-drawer-content>
      <main>
        <app-side-menu *ngIf="isAuthenticated && !partner" (toggleDrawer)="drawer.toggle()" (closeDrawer)="drawer.close()"
          [drawerActive]="drawerActive"></app-side-menu>
        <div class="wrapper">
          <router-outlet></router-outlet>
        </div>
      </main>
      <footer>
        <span>&#169; Rely IT - {{version}}</span>
      </footer>
    </mat-drawer-content>
  </mat-drawer-container>

  <app-order-info></app-order-info>
</ng-container>

<ng-container *ngIf="hideLayout">
  <div class="wrapper-no-layout">
    <router-outlet></router-outlet>
  </div>
</ng-container>