import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { AdvancePaymentReportDTO, DefaultService } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-advance-payments-report',
  templateUrl: './advance-payments-report.component.html',
  styleUrls: ['./advance-payments-report.component.scss'],
})
export class AdvancePaymentsReportComponent {
  advancePaymentReport?: AdvancePaymentReportDTO[];

  private onDestroy$ = new Subject<void>();

  constructor(
    private defaultService: DefaultService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.defaultService
      .getAdvancePaymnetReport()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.advancePaymentReport = value;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  downloadReport() {
    this.exportService.getReportAdvancePayments();
  }

  downloadReportDetails() {
    this.exportService.getReportAdvancePaymentDetails();
  }
}
