import { Component } from '@angular/core';

@Component({
  selector: 'app-anonymize',
  templateUrl: './anonymize.component.html',
  styleUrls: ['./anonymize.component.scss']
})
export class AnonymizeComponent {

}
